
export const RwaLiveBanner = () => {
    return (
        <div className={`rwa-banner-div`}>
            <div className="glowing-line" />
            <div className="content">
                <p>Xend Finance's Asset Chain Testnet is Live!</p>
                <a href="https://medium.com/xendfinance/xend-finances-l1-blockchain-asset-chain-s-testnet-is-now-live-e3dbf4e11d80" target="_blank" rel="noopener noreferrer">
                    <img src="/icons/arrow-right-white.svg" alt="real world assets staking is live" className="" />
                </a>
            </div>
        </div>
    );
}