import React from 'react'
import {Roll, Fade}  from "react-reveal";

type Props = {}

export const Team = (props: Props) => {
  return (
    <div className="team">
        <div className="page-title" id="team">
                <Fade bottom duration={3000}>
                    <h1 className="topic-1">
                        We Are Xend <br /> Finance And We Are <br /> On A Mission
                    </h1>
                    <h1 className="topic-1" id="team-sm">
                        We Are Xend Finance And We Are On A Mission
                    </h1>
                 {/* </Fade>
                <Fade bottom duration={3000} > */}
                    <h4 className="sub-topic-1" >
                    A company is only as good as the people behind it – meet our very talented team!
                    </h4>
                </Fade>
        </div>
        <section className="phase-2">
            <div className="left-side">
                <h2 className="about-us">About Us</h2>
                <p id="about-desc">
                    Xend Finance is a Binance and Google Launchpad-backed crypto bank for everyone that offers the best interest rates on your savings.
                    <br /> <br />
                    On a more technical level, Xend Finance is a global DeFi platform for credit unions, cooperatives, and individuals, with an open Layer-2 infrastructure for developers. Members can earn multiple levels of interest on their savings by seamlessly exchanging their crypto or fiat currency to stable cryptocurrencies and staking on lending platforms. 
                    <br /> <br />
                    Xend Finance recently released their Cross-Chain High Yield Aggregator, the first product in the industry that automatically scans and ensures the highest yields across multiple chains. Currently the product is available for BSC and Polygon.
                </p>
                {/* <img src="/icons/continue-reading.svg" alt="" className="continue" /> */}
            </div>
            
        </section>

        <section className="mission-sect">
            <Roll duration={3000}>
            <img src="/icons/6-sides.svg" alt="" className="six-sides" />
            </Roll>
            <div className="mission-box">
                <h2 className="our-mission">Our Mission</h2>
                <p className="mission-desc">
                To bring stable currency savings opportunities and the highest interest rates – in an easy, seamless, and secure manner – to millions of people around the world.
                </p>
            </div>
        </section>

        <section className="team-sect">
            <h4 className='team-head'>Meet The Management Team</h4>
            <p className='team-sub-head'>
                We're a team of talented individuals building platforms to power the future of decentralized finance 
            </p>
            <div className="members-cont">
                {teamArr.map((item: any, i: number) => (
                    <Member 
                        key={i}
                        name={item.name}
                        position={item.position}
                        linkedIn={item.linkedIn}
                        avatar={item.avatar}
                    />
                ))}
            </div>
        </section>
    </div>
  )
}

export const Member = ({name, position, linkedIn, avatar}: any) => {
    return (
        <div className="member">
            <img src={avatar} alt="" className="person" />
            <div className="details">
                <h3 className="name">{name}</h3>
                <h5 className="position">{position}</h5>
                <a href={linkedIn} target="_blank" rel="noopener noreferrer"  className="linkedin">
                    <img src="/icons/linkedin.svg" alt="" className="link-icon" />
                    <span>View LinkedIn Profile</span>
                </a>
            </div>
        </div>
    )
}


const teamArr = [
    {
        name: "Ugochukwu Aronu",
        position: "Co-Founder and CEO",
        linkedIn: "https://www.linkedin.com/in/ugochukwu-aronu-4477a59b/",
        avatar: "/icons/ugo.svg"
    },
    {
        name: "Emmanuel Okoro",
        position: "Co-Founder and Operations Lead",
        linkedIn: "https://www.linkedin.com/in/emmanuel-okoro-9163b480/",
        avatar: "/icons/okoro.png"
    },
    {
        name: "Chima Abafor",
        position: "CTO",
        linkedIn: "https://www.linkedin.com/in/chima-abafor-56801a90/",
        avatar: "/icons/abafor.png"
    },
    {
        name: "Kevin Leu",
        position: "Head of Marketing",
        linkedIn: "https://www.linkedin.com/in/kevinleu/",
        avatar: "/icons/kevin.png"
    },
    {
        name: "Chinelo Whitney",
        position: "Head of Growth",
        linkedIn: "https://www.linkedin.com/in/chinelowhitney/",
        avatar: "/icons/chinelo.png"
    },
    {
        name: "Chibuzo Emmanuel",
        position: "Lead Product Designer",
        linkedIn: "https://www.linkedin.com/in/chibuzoemmanuel/",
        avatar: "/icons/chibuzor.png"
    },
    {
        name: "Ebibote Opetu",
        position: "Communication and Content Creation Manager",
        linkedIn: "https://www.linkedin.com/in/ebibote-opetu-/",
        avatar: "/icons/ebibote.png"
    },
    {
        name: "Zacchaeus Akinwale",
        position: "Analytics Engineer",
        linkedIn: "https://www.linkedin.com/in/zacchakinwale/",
        avatar: "/icons/akinwale.png"
    },
]


//loi