import { SavingBox, savingsArr} from "components"
import { Zoom, Fade } from "react-reveal";

type Props = {}

export const MobileApp = (props: Props) => {
  return (
    <div className='mobile-page'>
        <section className="section-1">
            <div className="mobile-left">
                <Fade bottom duration={2500}>
                    <div className="page-title" id="mobile">
                        <h1 className="topic-1">
                            Xend Finance Mobile App
                        </h1>
                        {/* </Fade>
                        <Fade top duration={4000} > */}
                        <h4 className="sub-topic-1" >
                            Easily Convert Your Local Currency And Save In Stable Crypto Or US Dollars And Earn Up To 15% Annual Interest
                        </h4>
                    </div>
                </Fade>

                <div className="mobile-app"></div>
                    
                <Fade bottom duration={4000}>
                    <div className="clicks">
                        <a href={process.env.REACT_APP_XEND_PLAYSTORE_URL} target="_blank" rel="noopener noreferrer" className="app-dwnld" >
                            <img src="/icons/google.svg" alt="" className="google-img" />
                        </a>
                        <a href={process.env.REACT_APP_XEND_APPSTORE_URL} target="_blank" rel="noopener noreferrer" className="app-dwnld" >
                            <img src="/icons/apple.svg" alt="" className="google-img" />
                        </a>
                        <a href={process.env.REACT_APP_XEND_APK_URL} target="_blank" rel="noopener noreferrer" className="app-dwnld" >
                            <img src="/icons/apk.svg" alt="" className="google-img" />
                        </a>
                    </div>
                </Fade>
            </div>
            <div className="mobile-right"></div>
        </section>
      <section className="saving-sect">
        <Fade bottom duration={2000}>
            <h2 className="topic-3">Savings Benefits</h2>
        </Fade>
        <p className="optimize">
            Xend Finance has created a highly flexible and robust infrastructure 
            to become a Layer-2 DeFi protocol.  
        </p>
        <main className="savings-points">
            {savingsArr.map((item: any, i: number) => (
                <SavingBox
                    key={i} 
                    icon={item.icon}
                    topic={item.topic}
                    content={item.content}
                />
            ))}
        </main>
      </section>
    {/* <section className="min-cards">
        <Zoom duration={2000} top={true}>
            <div className="card">
                <h4 className="title-min">Mandela</h4>
                <p className="min-cont">
                    Save at your own pace. It is flexible and you can withdraw at any time or lock your savings to earn higher ROI.
                </p> */}
                {/* <a href="https://app.xend.finance/personal" target="_blank" rel="noopener noreferrer">
                    <Button
                        text="Get started"
                        className='secondary saving-btn'
                    />
                </a> */}
            {/* </div>
        </Zoom>
            <div className="card">
                <h4 className="title-min">Mansa</h4>
                <p className="min-cont">
                    Create a cooperative with multiple savings plans and custom features.
                </p> */}
                {/* <a href="https://app.xend.finance/cooperative" target="_blank" rel="noopener noreferrer">
                    <Button
                        text="Get started"
                        className='secondary saving-btn'
                    /> 
                </a> */}
            {/* </div>
        <Zoom duration={2000} top={true}>
            <div className="card">
                <h4 className="title-min">Ojukwu</h4>
                <p className="min-cont">
                    Save with friends. You get ROI on everyone’s deposit and you can withdraw your ROI immediately. 
                </p> */}
                {/* <a href="https://app.xend.finance/esusu" target="_blank" rel="noopener noreferrer">
                    <Button
                        text="Get started"
                        className='secondary saving-btn'
                    />
                </a> */}
            {/* </div>
        </Zoom>
    </section> */}
    {/* <section className="mobile-sect">
        <div className="left-side">
            <h3 className='topic-4'>
                Get Our Mobile App, 
                <br />
                Save Anytime, Anywhere
            </h3>
            <p className="sub-topic-4">Lorem ipsum dolor sit amet, consectetur adipiscing</p>
            <div className="download-btns">
                <a href={process.env.REACT_APP_XEND_APPSTORE_URL} target="_blank" rel="noopener noreferrer" className="dwnld">
                    <img src="/icons/app-store.svg" alt=""  />
                </a>
                <a href={process.env.REACT_APP_XEND_PLAYSTORE_URL} target="_blank" rel="noopener noreferrer" className="dwnld">
                    <img src="/icons/google-play.svg" alt="" className="dwnld" />
                </a>
                
            </div>
        </div>
        <div className="right-side"></div>
    </section> */}
    {/* <section className="trustee-sect">
        <div className="trust-left">
            <Roll duration={3000}>
                <img src="/icons/trusted.png" alt="" className="people" />
            </Roll>
        </div>
        <div className="trust-right">
            <h3 className='topic-5'>
                Trusted By Over 
                <br />
                50 Thousand users
            </h3> */}
            {/* <p className="sub-topic-4">Lorem ipsum dolor sit amet, consectetur adipiscing</p> */}
        {/* </div>
    </section> */}

    <section className="new-trustee-sect">
        <Zoom>
            <h3 className='new-topic-5'>
                TRUSTED BY OVER 100K+ <br/>
                <span className='new-topic-span'>
                    Users around the world
                </span>
            </h3>
        </Zoom>
    </section>

    <section className="more-sect">
        <div className="more-box">
                <h3 className="get-started">Start saving in stable currency</h3>
                {/* <p className="dets">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis
                </p> */}
                <div className="more-btns">
                <a href={process.env.REACT_APP_XEND_PLAYSTORE_URL} target="_blank" rel="noopener noreferrer">
                    <img src="/icons/google-white.svg" alt="" className="download" />
                </a>
                <a href={process.env.REACT_APP_XEND_APPSTORE_URL} target="_blank" rel="noopener noreferrer">
                    <img src="/icons/appstore-white.svg" alt="" className="download" />
                </a>
                <a href={process.env.REACT_APP_XEND_APK_URL} target="_blank" rel="noopener noreferrer">
                    <img src="/icons/apk-white.svg" alt="" className="download" />
                </a>
                </div>
        </div>
    </section>
    </div>
  )
}



