import { Feature, featureData} from "components"
import {_copyToClipboard} from "utils"
import {useLocation, useNavigate} from "react-router-dom"
import { Fade } from "react-reveal";
import { NewRoadmap } from "components/NewRoadmap";
// import {newsArray} from "components/data"

type Props = {}

export const Home = (props: Props) => {
  const navigate = useNavigate()

  // const bscAddress = "0x36fe11b6d5c9421f68d235694fe192b35e803903"
  // const ethereumAddress = "0x4563554284aA7148D6E6D0351519E954Ba3B6E02"
  // const polygonAddress = "0x36fe11b6d5c9421f68d235694fe192b35e803903"
  // const huobiAddress = "0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F"
  // const arbitrumAddress = "0x3096e7bfd0878cc65be71f8899bc4cfb57187ba3"

  
  return (
    <div className="home">
        <div className="step-1">
        <Fade bottom duration={1500} >
          <div className="page-title" id="home">
            
              <h1 className="topic-1" >
              Revolutionizing Finance with Cryptocurrency-backed Real World Assets (RWA)
              </h1>
            

            {/* <Fade bottom duration={8000} > */}
              <h4 className="sub-topic-1">
                New Era of Financial Freedom: Explore the Fusion of Cryptocurrency and Real World Assets on Xend Finance 
                <br />
                {/* <span className='sub-topic-1-span'>Earn Up To 15% Annual Interest</span> */}
              </h4>
            {/* </Fade> */}
          </div>

          <div className="buttons-box">
            {/* <Button
              text="Launch dApp"
              type="button"
            /> */}
            <a href={process.env.REACT_APP_XEND_PLAYSTORE_URL} target="_blank" rel="noopener noreferrer" className="app">
              <img src="/icons/google-play.svg" alt="google play" className="app-btn" />
            </a>
            <a href="https://apps.apple.com/us/app/xend-finance/id1587050825" target="_blank" rel="noopener noreferrer" className="app">
              <img src="/icons/app-store.svg" alt="google play" className="app-btn"  />
            </a>
            <a href={process.env.REACT_APP_XEND_APK_URL} target="_blank" rel="noopener noreferrer" className="app">
              <img src="/icons/apk.svg" alt="google play" className="app-btn"  />
            </a>
          </div>
          </Fade>

            {/* <div className="devices-box">
              <img src="/icons/Xend_Finance_Screen_Mockup.svg" alt="" className="devices" />
            </div> */}

            <div className="insurances">
              <a href='https://medium.com/tidal-finance/xend-finance-buys-1-million-worth-of-coverage-tvl-through-tidal-finance-12648b604d08' target="_blank" rel="noopener noreferrer" className="tidal-cont" >
                <p>Funds insured by</p>
                <img src="/icons/tidal-dark.svg" alt="" />
              </a>
              <a href='https://www.certik.com/projects/xendfinance' target="_blank" rel="noopener noreferrer" className="tidal-cont" >
                <p>Audited by</p>
                <img src="/icons/certik-dark.svg" alt="" />
              </a>
              <a href='https://techrate.org/' target="_blank" rel="noopener noreferrer" className="tidal-cont" >
                <p></p>
                <img src="/icons/techrate.svg" alt="" />
              </a>
              <a href='https://www.vidma.io/' target="_blank" rel="noopener noreferrer" className="tidal-cont" >
                <p></p>
                <img src="/icons/vidma.png" alt="" />
              </a>
              <a href='https://omniscia.io/' target="_blank" rel="noopener noreferrer" className="tidal-cont" >
                <p></p>
                <img src="/icons/Omniscia.svg" alt="" />
              </a>
              
            </div>
          
        </div>
        
        <div className="feature-box">
          <p className="featured">Award-Winning Technology Featured On</p>
          <div className="card-box">
          <Fade bottom duration={1500}>
            <a href="https://www.coindesk.com/binance-labs-xend-finance-nigeria-defi" target="_blank" rel="noopener noreferrer" className="card">
            {/* <div className="card"> */}
              <div className="top">
                <img src="/icons/coindesk.svg" alt="coindesk" className="feat-logo" />
                <a href="ht" target="_blank" rel="noopener noreferrer" className='go-link'>
                  <img src="/icons/right.svg" alt="" className="go-icon" />
                </a>
              </div>
              <p className="summary">
                “Binance Labs-Backed 'DeFi Credit Union' Bringing Higher Yields to Savers in Nigeria”
              </p>
            {/* </div> */}
            </a>
            </Fade>

            <Fade bottom duration={1500}>
            <a href="https://techcrunch.com/2021/03/22/binance-backed-xend-finance-launches-defi-platform-for-credit-unions-in-africa/" target="_blank" rel="noopener noreferrer" className="card">
              <div className="top">
                <img src="/icons/techcrunch.svg" alt="coindesk" className="feat-logo" />
                <a href="" target="_blank" rel="noopener noreferrer" className='go-link'>
                  <img src="/icons/right.svg" alt="" className="go-icon" />
                </a>
              </div>
              <p className="summary">
                Binance-backed Xend Finance launches DeFi platform for credit unions in Africa
              </p>
            </a>
            </Fade>

            <Fade bottom duration={1500}>
            <a href="https://decrypt.co/62369/xend-finance-is-bringing-defi-services-to-africa" target="_blank" rel="noopener noreferrer" className="card">
              <div className="top">
                <img src="/icons/decrypt.svg" alt="decrypt" className="feat-logo" />
                <a href="" target="_blank" rel="noopener noreferrer" className='go-link'>
                  <img src="/icons/right.svg" alt="" className="go-icon" />
                </a>
              </div>
              <p className="summary">
                Xend Finance is Bringing DeFi Services to Africa
              </p>
            </a>
            </Fade>
          </div>
        </div>
        <div className="devs-section">
          <p className="devs-title">For Developers</p>
          <p className="devs-desc">
            Xend Finance has created a highly flexible and robust infrastructure to become a Layer-2 DeFi protocol. Composed of multiple Layer-1 DeFi protocols across different blockchains to provide better yields, as opposed to using Layer-1 DeFi yield platforms individually, we invite developers to build for free.
          </p>
          <div className="devs-btn">
            {/* <Button
              text={
                <div className="btn-text">
                  <img src="/icons/download-sdk.svg" alt="" />
                  <span>Download SDK</span>
                </div>
              }
              className="white-btn"
            /> */}
            <a href="https://www.npmjs.com/package/@xend-finance/web-sdk" target="_blank" rel="noopener noreferrer" className="btn-sdk">
              <img src="/icons/btn-sdk.svg" alt="" />
            </a>
            <p className="learn-more" onClick={() => navigate("/developer")}>
              <span>Learn More</span>
              <img src="/icons/learn-more.svg" alt="" />
            </p>
          </div>
        </div>

        {/* <section className="token-sect" id="token">
          <p className="topic-2">$RWA Token</p>
          <p className="token-desc">
            The Xend token ($RWA) powers a decentralized savings and cross-chain high yield aggregator protocol.
            The $RWA tokens also generate interest for the users as they hold on to the tokens.
          </p>
          <p className="tokens-chains">
            Xend Finance Tokens have been issued on below public chains:
          </p>

          <div className="rows">
          <Fade bottom duration={1500}>
            <div className="token-rows">
              <img src="/icons/bsc.svg" alt="" className="blockchain" />
              <a href="https://bscscan.com/token/0x36fe11b6d5c9421f68d235694fe192b35e803903" target="_blank" rel="noopener noreferrer" className="token-dets">
                <p className="token-name">Binance Smart Chain</p>
                <p className="address">{bscAddress}</p>
              </a>
              <img src="/icons/Copy.svg" alt="copy" className="copy" 
                onClick={() => _copyToClipboard(bscAddress, "Bsc Address Copied")}
              />
            </div>
            </Fade>

            <Fade bottom duration={1700}>
            <div className="token-rows">
              <img src="/icons/ethereum.svg" alt="" className="blockchain" />
              <a href="https://etherscan.io/address/0x4563554284aA7148D6E6D0351519E954Ba3B6E02" target="_blank" rel="noopener noreferrer" className="token-dets">
                <p className="token-name">Ethereum</p>
                <p className="address">{ethereumAddress}</p>
              </a>
              <img src="/icons/Copy.svg" alt="copy" className="copy" 
                onClick={() => _copyToClipboard(ethereumAddress, "Ethereum Address Copied")}
              />
            </div>
            </Fade>

            <Fade bottom duration={1900}>
            <div className="token-rows">
              <img src="/icons/polygon.svg" alt="" className="blockchain" />
              <a href="https://polygonscan.com/token/0x36fe11b6d5c9421f68d235694fe192b35e80390" target="_blank" rel="noopener noreferrer" className="token-dets">
                <p className="token-name">Polygon Eco Chain</p>
                <p className="address">{polygonAddress}</p>
              </a>
              <img src="/icons/Copy.svg" alt="copy" className="copy" 
                onClick={() => _copyToClipboard(polygonAddress, "Polygon Address Copied")}
              />
            </div>
            </Fade>

            <Fade bottom duration={2100}>
            <div className="token-rows">
              <img src="/icons/arbitrum.svg" alt="" className="blockchain" />
              <a href="https://arbiscan.io/token/0x3096e7bfd0878cc65be71f8899bc4cfb57187ba3" target="_blank" rel="noopener noreferrer" className="token-dets">
                <p className="token-name">Arbitrum</p>
                <p className="address">{arbitrumAddress}</p>
              </a>
              <img src="/icons/Copy.svg" alt="copy" className="copy" 
                onClick={() => _copyToClipboard(arbitrumAddress, "Arbitrum Address Copied")}
              />
            </div>
            </Fade>
          </div>
          
          <Fade bottom duration={1500}>
            <h3 className="get">Get $RWA Here</h3>
            <p className="xchange">Exchanges that offer $RWA Tokens</p>
            <div className="xchanges-box"> */}
              {/* <a href="https://www.gate.io/trade/XEND_USDT/?ch=en_sm_0321e" target="_blank" rel="noopener noreferrer" className="x-logo">
                <img src="/icons/gate-io.svg" alt=""  />
              </a> */}
              {/* <a href="//https://ascendex.com/en/basic/cashtrade-spottrading/usdt/xend" target="_blank" rel="noopener noreferrer" className="x-logo">
                <img src="/icons/ascendex.svg" alt=""  />
              </a> */}
              {/* <a href="https://app.uniswap.org/tokens/arbitrum/0x3096e7bfd0878cc65be71f8899bc4cfb57187ba3" target="_blank" rel="noopener noreferrer" className="x-logo">
                <img src="/icons/uniswap.svg" alt="" />
              </a>
              <a href="https://www.dextools.io/app/en/arbitrum/pair-explorer/0x066b28f0c160935cf285f75ed600967bf8417035" target="_blank" rel="noopener noreferrer" className="x-logo">
                <img src="/icons/dextools.svg" alt="" />
              </a> */}
              {/* <a href="https://exchange.pancakeswap.finance/#/swap" target="_blank" rel="noopener noreferrer" className="x-logo">
                <img src="/icons/pancakeswap.svg" alt="" />
              </a> */}
            {/* </div>
          </Fade>
        </section> */}

        <section className="step-3">
              <h2 className="topic-3">Features</h2>
              <p className="optimize">Every tool you need to optimize your savings  and Investment potential</p>
              <div className="feat-box">
                {featureData.map((feat: any, i: any) => {
                  return (
                    <Feature
                      key={i}
                      icon={feat.icon}
                      feature={feat.feature}
                      desc={feat.desc}
                    />
                )})}
              </div>
        </section>

        {/* <section className="step-4" id="roadmap">
          <h2 className="topic-4">The Journey So Far And The Road To Come</h2>
          <p className="topic-4-sub">
            Xend Finance has crossed many incredible milestones, 
            with many more to come. 
            Below is a detailed roadmap that lays out the past, the present, 
            and a little sneak-peak into the future.
          </p>
          <Fade bottom duration={1500}>
            <div className="journey-box">
              <Roadmap />
            </div>
          </Fade>
        </section> */}

        <section>
          <NewRoadmap />
        </section>

        <section className="step-5">
            <h2 className="topic-5">Our Top Investors</h2>
            {/* <p className="sub-topic-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis</p> */}
            <div className="partners">
              <Fade bottom duration={1500}>
              <img src="/icons/haskey.svg" alt="" className="partner" />
              <img src="/icons/google-launchpad-3.svg" alt="" className="partner" />
              {/* <img src="/icons/twin-apex.svg" alt="" className="partner" /> */}
              <img src="/icons/binance.png" alt="" className="partner" />
              {/* <img src="/icons/au21cap.svg" alt="" className="partner" />
              <img src="/icons/ngc.svg" alt="" className="partner" />
              <img src="/icons/long-hash.svg" alt="" className="partner" />
              <img src="/icons/delta-hub.svg" alt="" className="partner" />
              <img src="/icons/amplifi.svg" alt="" className="partner" />
              <img src="/icons/gains.svg" alt="" className="partner" />
              <img src="/icons/paralink.svg" alt="" className="partner" />
              <img src="/icons/blockbank.svg" alt="" className="partner" />
              <img src="/icons/defi-dollar.svg" alt="" className="partner" />
              <img src="/icons/fortube.svg" alt="" className="partner" />
              <img src="/icons/dafi.svg" alt="" className="partner" />
              <img src="/icons/biconomy.svg" alt="" className="partner" /> */}
              </Fade>
            </div>
            {/* <img src="/icons/partners.svg" alt="" className="all-partners" /> */}
        </section>

        <section className="step-6" id="news">
          <div className="topic-box">
            <p className="topic-6">News/Announcements</p>
            <p className="sub-topic-6">Latest news from our blog and press releases</p>
          </div>
            <main className="broadcast">
              <div className="left">
                <p className='news-topic'>Press Coverage</p>
                <div className="home-press">
                  <Fade bottom duration={1500}>
                    <div className="news-img">
                      <img src="/icons/xend-partners-with-rise.png" alt="" id="img-fit" />
                    </div>
                  </Fade>
                  
                  <Fade bottom duration={1500}>
                    <div className="news-box">
                      <p id="media">
                        <span>MEDIA</span>
                        <span id="news-date">/ December 22, 2023 /</span>
                        <span>Medium</span>
                        </p>
                      <h3 className="main-topic">Landmark Partnership Unlocks Access To Regulated Financial Assets For Africans In The DeFi Economy</h3>
                      <p className="main-content">
                      Xend Finance Partners With Risevest To Bridge TradFi (Traditional Finance) And DeFi (Decentralised Finance), 
                      Allowing Stablecoin Transactions In TradFi, And Access To RWA (Real-World Assets) In DeFi.                         
                      </p>
                      <div className="read-box">
                      <a href="https://medium.com/xendfinance/landmark-partnership-unlocks-access-to-regulated-financial-assets-for-africans-in-the-defi-economy-225487f66ba6" target="_blank" rel="noopener noreferrer" 
                        className="read"
                      >
                        <span>Read More</span>
                        <img src="/icons/arrow-right.svg" alt="" className="arrow" />
                      </a>
                      </div>
                      <div className="news-hr"></div>
                    </div>
                  </Fade>
              
                  <Fade bottom duration={1800}>
                    <NewsSection 
                      headline="Nigeria’s Xend Finance adds to product suite of its global crypto bank"
                      link="https://disrupt-africa.com/2022/08/03/nigerias-xend-finance-adds-to-product-suite-of-its-global-crypto-bank/"
                      date="August 3, 2022"
                      platform= "Disrupt Africa"
                    />
                  </Fade>

                  {/* <NewsSection 
                    headline="Xend Finance to help Businesses with New Crypto Payment System"
                    link="https://cryptotvplus.com/2022/07/xend-finance-to-help-businesses-with-new-crypto-payment-system/"
                    date="June 29, 2022"
                    platform= "Cryptotvplus"
                  /> */}
                  
                  <div className="news-btn-cont">
                    <div className="see-cont">
                    <div className="news-btn" onClick={() => navigate("/news")}>
                        <span className='see-all'>See all</span>
                        <img src="/icons/arrow-right.svg" alt="" className="arrow" />
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>

              <div className="right">
                <p className='news-topic'>Blog Posts</p>
                <div className="home-press">
                  <Fade bottom duration={1500}>
                    <div className="news-img">
                      <img 
                        src="https://miro.medium.com/v2/resize:fit:720/format:webp/0*43V_Hhfxhr-zesm_" 
                        alt="Xend Finance’s L1 Blockchain, Asset Chain’s Testnet is Now Live" 
                        id="img-fit" 
                      />
                    </div>
                  </Fade>
                  
                  <Fade bottom duration={1500}>
                    <div className="news-box">
                      <p id="media">
                        <span>MEDIA</span>
                        <span id="news-date">/ June 20, 2024 /</span>
                        <span>Medium</span>
                        </p>
                      <h3 className="main-topic">Xend Finance’s L1 Blockchain, Asset Chain’s Testnet is Now Live!</h3>
                      <p className="main-content">
                        Xend Finance has introduced the Asset Chain Testnet, which is the native blockchain for their Real-World Asset OAE. 
                        This Testnet allows for easy and smooth tokenization, verification, 
                        and management of real-world assets on-chain, providing diverse investment opportunities. 
                        The Xend community can participate in tasks and airdrops to experience these features firsthand.
                      </p>
                      <div className="read-box">
                      <a href="https://medium.com/xendfinance/xend-finances-l1-blockchain-asset-chain-s-testnet-is-now-live-e3dbf4e11d80" target="_blank" rel="noopener noreferrer" 
                        className="read"
                      >
                          <span>Read More</span>
                          <img src="/icons/arrow-right.svg" alt="" className="arrow" />
                      </a>
                      </div>
                      <div className="news-hr"></div>
                    </div>
                  </Fade>

                  <Fade bottom duration={1800}>
                    <NewsSection 
                      headline={"Xend Finance Partners With GT Protocol To Power XEND-AI And Assist Our Users Make Better Investment"}
                      link={"https://medium.com/xendfinance/xend-finance-partners-with-gt-protocol-to-power-xend-ai-and-assist-our-users-make-better-investment-41f902baddc8"}
                      date={"December 15, 2023"}
                      platform="Medium"
                    />
                  </Fade>
                  {/* <Fade bottom duration={1800}>
                    <NewsSection 
                      headline={"What An Incredible Start To 2022 For Xend Finance!"}
                      link={"https://xendfinance.substack.com/p/what-an-incredible-start-to-2022?utm_source=url&s=r"}
                      date={"March 3, 2022"}
                      platform="Substack"
                    />
                  </Fade> */}
                 

                  <div className="news-btn-cont">
                    <div className="see-cont">
                      <a href="https://medium.com/xendfinance" target="_blank" rel="noopener noreferrer" className="news-btn">
                        <span className='see-all'>See all</span>
                        <img src="/icons/arrow-right.svg" alt="" className="arrow" />
                      </a>
                    </div>
                  </div>
                </div>
               
                  
              </div>
            </main>
        </section>
    </div>
  )
}


export const NewsSection = ({headline, link, date, platform}: any) => {
  const {pathname} = useLocation()

  return (
    <div className='home-news'>
        <div className="news-box">
          <p id="media">
            <span>MEDIA</span>
            <span id="news-date">/ {date} /</span>
              <span style={{marginLeft: "0.3rem"}}>{platform}</span>
          </p>
          <h3 className="main-topic">{headline}</h3>
          {/* <p className="main-content">
              Xend Finance today launched a platform to enable credit unions and co-ops throughout Africa to provide DeFi services to their members.
          </p> */}
          <div className="read-box">
            <a href={link} target="_blank" rel="noopener noreferrer" className="read">
                <span>Read More</span>
                <img src="/icons/arrow-right.svg" alt="" className="arrow" />
            </a>
          </div>
         {pathname !== "/" && <div className="news-hr"></div>}
        </div>
        
    </div>
  )
}


