import {useRef} from 'react'
import {  Fade } from "react-reveal";
import {horizontalScroll} from "utils"
import { NewsSection } from './Home';
import {newsArray} from "components/data"

type Props = {}

export const Media = (props: Props) => {
    const contentWrapper = useRef(null);


  return (
    <div className="media">
        <div className="top-1">
            <Fade bottom duration={4000}>
                <div className="page-title" id="media">
                        <h1 className="topic-1">
                        Xend Media
                        </h1>
                        <h4 className="sub-topic-1" >
                            Press information, media kits, and the latest news about Xend can be found here.
                        </h4>
                    
                </div>
            
                <a href="https://mega.nz/folder/gfRyRLIS#fY-NU1hwszGWSTJQKcAqpw" target="_blank" rel="noopener noreferrer" className="media-kit">
                    <img src="/icons/media-btn.svg" alt="" />
                </a>
                
            </Fade>
        </div>

        <section className="news-sect">
            <div className="press-top">
                <h2 className="news-title">Press Coverage</h2>
                {/* <div className="arrs">
                    <img src="/icons/circled-arr-left.svg" alt="" 
                        onClick={() => {
                            contentWrapper.current &&
                            horizontalScroll(contentWrapper.current!, 25, 100, -10)
                            console.log("scroll left")
                        }}
                    />
                    <img src="/icons/circled-arr-right.svg" alt="" 
                        onClick={() => {
                            contentWrapper.current &&
                            horizontalScroll(contentWrapper.current!, 25, 100, 10)
                            console.log("scroll right")
                        }}
                    />
                </div> */}
            </div>
            <div className="main-news" 
                //ref={contentWrapper}
            >
                <div className="latest-news">
                    <img src="/icons/xend-partners-with-rise.png" alt="" id="img-fit" className='img-fit' />
                    <div className="news-box">
                        <p id="media">
                        <span>MEDIA</span>
                        <span id="news-date">/ December 22, 2023 /</span>
                        <span>Medium</span>
                        </p>
                        <h3 className="main-topic">Landmark Partnership Unlocks Access To Regulated Financial Assets For Africans In The DeFi Economy</h3>
                        <p className="main-content">
                            Xend Finance Partners With Risevest To Bridge TradFi (Traditional Finance) And DeFi (Decentralised Finance), 
                            Allowing Stablecoin Transactions In TradFi, And Access To RWA (Real-World Assets) In DeFi.                             
                        </p>
                        <div className="read-box">
                        <a href="https://medium.com/xendfinance/landmark-partnership-unlocks-access-to-regulated-financial-assets-for-africans-in-the-defi-economy-225487f66ba6" 
                        className="read"
                        >
                            <span>Read More</span>
                            <img src="/icons/arrow-right.svg" alt="" className="arrow" />
                        </a>
                        </div>
                    </div>
                </div>
                <div className="news-rows">
                    {newsArray.map((news: any, i: number) => (
                        <NewsSection 
                            key={i}
                            headline={news.headline}
                            link={news.link}
                            date={news.date}
                            platform={news.platform}
                        />
                    ))}
                </div>
            </div>
        </section>
    </div>
  )
}

const NewsBox = () => {
    return (
        <div className="news-cont">
            <img src="/icons/polygon-bg.png" alt="" className="news-img" />
            <div className="news-box">
                <p id="media">
                        <span>MEDIA</span>
                        <span id="news-date">/ 2021 - 03 - 21 /</span>
                        <span>DECRYPT</span>
                </p>
                <h3 className="main-topic">Polygon Integrates Xend Finance</h3>
                <p className="main-content">
                        Xend Finance today launched a platform to enable credit unions and co-ops throughout Africa to provide DeFi services to their members.
                </p>
                <p className="read">
                        <span>Read More</span>
                        <img src="/icons/arrow-right.svg" alt="" className="arrow" />
                </p>
            </div>
        </div>
    )
}