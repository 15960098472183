import { Layout } from 'components';
import {useEffect} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { Team } from 'screens/Team';
import { Home } from 'screens/Home';
import { Developer } from 'screens/Developer';
import { Faq } from 'screens/Faq';
import { Media } from 'screens/Media';
import { Business } from 'screens/Business';
import { MobileApp } from 'screens/MobileApp';
import { Token } from 'screens/Token';

import ReactGA from 'react-ga';
import { PrivacyPolicy } from 'screens/PrivacyPolicy';
import { TermsAndConditions } from 'screens/TermsAndConditions';


const ScrollToTop = (props: any) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};


function App() {
  const TRACKING_ID = "G-903YD02LL5"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="app">
      <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" 
            element={
              <Layout>
                <Home />
              </Layout>
            } 
          />

          <Route path="/developer" 
            element={
              <Layout>
                <Developer />
              </Layout>
            } 
          />

          <Route path="/team" 
            element={
              <Layout>
                <Team />
              </Layout>
            } 
          />

          <Route path="/faq" 
            element={
              <Layout>
                <Faq />
              </Layout>
            } 
          />

          <Route path="/media" 
            element={
              <Layout>
                <Media />
              </Layout>
            } 
          />

          <Route path="/news" 
            element={
              <Layout>
                <Media />
              </Layout>
            } 
          />

          <Route path="/business" 
            element={
              <Layout>
                <Business />
              </Layout>
            } 
          />

          <Route path="/token" 
            element={
              <Layout>
                <Token />
              </Layout>
            } 
          />

          <Route path="/policy" 
            element={
              <Layout>
                <PrivacyPolicy />
              </Layout>
            } 
          />

          <Route path="/terms" 
            element={
              <Layout>
                <TermsAndConditions />
              </Layout>
            } 
          />

          {/* <Route path="/savings" 
            element={
              <Layout>
                <Savings />
              </Layout>
            } 
          /> */}
          
          <Route path="/mobileapp.html" 
            element={
              <Layout>
                <MobileApp />
              </Layout>
            } 
          />
        </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;

