import React from 'react'
import {Button, Feature, featureData} from "components"
import {  Fade } from "react-reveal";
import {useGoogleAnalyticsEventTracker} from "../customHooks/UseGoogleAnalytics"


type Props = {}

export const Business = (props: Props) => {
  const gaEventTracker = useGoogleAnalyticsEventTracker('Email');

  return (
    <div className='business-page'>
      <div className="page-title" id="business">
        <Fade bottom duration={4000}>
            <h1 className="topic-1">
              A Global DeFi Platform for Credit Unions, Cooperatives, and Individuals
            </h1>
        </Fade>
        <Fade top duration={4000} >
            <h4 className="sub-topic-1" >
              You And Your Organization Can Access DeFi, Stable Currencies, And Up To 15% APY On Your Savings
            </h4>
        </Fade>
      </div>
      <div className="clicks">
            <div className="click-1">
              <a href="mailto:hello@xend.finance"
                onClick={() => gaEventTracker('email')}
              >
                <Button
                  text="Contact a Fund Manager"
                  className='invest-btn'
                />
              </a>
              <a href="http://autoyield.xend.finance" target="_blank" rel="noopener noreferrer">
                <Button
                  text="Access 15% Interest Now"
                  className="secondary"
                />
              </a>
                {/* <img src="/icons/read-docs.svg" alt="" className="dev-btn" /> */}
            </div>
            {/* <img src="/icons/watch.svg" alt="" className="dev-btn" /> */}
      </div>
      {/* <div className="table-img">
        <img src="/icons/table.svg" alt="" />
      </div> */}
      <div className="feedbacks-sect">
        {feedbackArr.map((item: any, i: number) => (
          <Feedbacks 
            name={item.name}
            avatar={item.avatar}
            portfolio={item.portfolio}
            content={item.content}
          />
        ))}
      </div>
      <section className="benefit-sect">
              <h2 className="topic-3">Benefits</h2>
              <p className="optimize">
                Xend Finance has created a highly flexible and robust infrastructure 
                to become a Layer-2 DeFi protocol. 
              </p>
              <div className="feat-box">
                {featureData.map((feat: any, i: any) => {
                  return (
                    <Feature
                      key={i}
                      icon={feat.icon}
                      feature={feat.feature}
                      desc={feat.desc}
                    />
                )})}
              </div>
      </section>

      <section className="business-sect">
        <h2 className='topic'>Business Solutions</h2>
        <p className='min-topic'>Complete tools to empower your business</p>
        <div className="item-cards">
          <div className="item">
            {/* <div id="logo"> */}
              <img src="/icons/switch-wallet.svg" alt="" className="logo" />
            {/* </div> */}
            <div className="the-content">
              <div id="top">
                <p id="title">SwitchWallet</p>
                <p className="content">
                  SwitchWallet is a wallet service that allows developers to create and manage wallets for their users on EVM compatible blockchains as well as Bitcoin and Bitcoin Derivative blockchains.
                </p>
              </div>
              <a href="https://switchwallet.io" target="_blank" rel="noopener noreferrer" className="learn">
                <span>Learn More</span>
                <img src="/icons/arrow-right.svg" alt="" className="arrow-right" />
              </a>
            </div>
          </div>

          <div className="item">
            {/* <div id="logo"> */}
              <img src="/icons/xend-icon.svg" alt="" className="logo" />
            {/* </div> */}
            <div className="the-content">
              <div id="top">
                <p id="title">Xend Finance AutoYield SDK</p>
                <p className="content">
                  Our SDK enables other developers to build on the Xend Finance AutoYield protocols such as xVault and xAuto. We also have the SDK-UI which is a javascript plugin for developers that want to integrate Xend Finance directly with very little code. Our SDK makes it easy for non-blockchain developers to access DeFi.
                </p>
              </div>
              <a href="https://www.npmjs.com/package/@xend-finance/web-sdk" target="_blank" rel="noopener noreferrer" className="learn">
                <span>Learn More</span>
                <img src="/icons/arrow-right.svg" alt="" className="arrow-right" />
              </a>
            </div>
        </div>

        <div className="item">
            {/* <div id="logo"> */}
              <img src="/icons/xend-bridge.svg" alt="" className="logo" />
            {/* </div> */}
            <div className="the-content">
              <div id="top">
                <p id="title">XendBridge</p>
                <p className="content">
                XendBridge enables users to convert their local currencies to crypto currencies and vice versa. The XendBridge comes as a library for android or iOS and also an API for developers to integrate into their web or mobile apps.
              </p>
              </div>
              <a href="https://xendbridge.com" target="_blank" rel="noopener noreferrer" className="learn">
                <span>Learn More</span>
                <img src="/icons/arrow-right.svg" alt="" className="arrow-right" />
              </a>
            </div>
        </div>
        </div>
      </section>

      {/* <section className="offers-sect">
        <div className="offer-left">
          <h3 className="limited-offer">Limited Offer</h3>
          <p className="offer-content">
            Financial solutions fund the fed aggressive revenue growth customer value chain The Financial Review interbank lending all about the Benjamins and money moves.
          </p>
          <Button
            text="Get Started"
            className="secondary"
          />
        </div>
        <div className="offer-right">
          <Fade duration={3000} right>
          <img src="/icons/offer-icon.svg" alt="" className="offer-icon" />
          </Fade>
        </div>
      </section> */}

      <section className="learn-sect">
        <div className="learn-left">
          <Fade duration={3000} left>
          <img src="/icons/learn-img.svg" alt="" className="offer-icon" />
          </Fade>
        </div>
        <div className="learn-right">
          <h3 className="limited-offer">Learn More</h3>
          <p className="offer-content">
          You and your organization can access the best of DeFi, Web3, and Blockchain through Xend Finance’s white-labeled technology on your own platform.
          </p>
          <a href="https://docs.xend.finance/" target="_blank" rel="noopener noreferrer">
          <Button
            text="Go to Docs"
            className="secondary"
          />
          </a>
        </div>
      </section>

      <section className="partners-sect">
        <h3 className="investment-partners">Investment and Business Partners</h3>
        <div className="partners">
              <img src="/icons/haskey.svg" alt="" className="partner" />
              <img src="/icons/google-launchpad-2.svg" alt="" className="partner" />
              <img src="/icons/twin-apex.svg" alt="" className="partner" />
              <img src="/icons/binance.svg" alt="" className="partner" />
              <img src="/icons/au21.svg" alt="" className="partner" />
              <img src="/icons/ngc.svg" alt="" className="partner" />
              <img src="/icons/long-hash.svg" alt="" className="partner" />
              <img src="/icons/delta-hub.svg" alt="" className="partner" />
              <img src="/icons/amplifi.svg" alt="" className="partner" />
              
              {/* --------------new------------------------- */}
              <img src="/icons/gains.svg" alt="" className="partner" />
              <img src="/icons/paralink.svg" alt="" className="partner" />
              <img src="/icons/blockbank.svg" alt="" className="partner" />
              <img src="/icons/defi-dollar.svg" alt="" className="partner" />
              <img src="/icons/fortube.svg" alt="" className="partner" />
              <img src="/icons/dafi.svg" alt="" className="partner" />
              <img src="/icons/biconomy.svg" alt="" className="partner" />
          </div>
      </section>

      <section className="more-box">
        <h3 className="get-started">Get Started</h3>
        <p className="dets">
        Consult with an expert today
        </p>
        <div className="more-btns">
          <a href="mailto:hello@xend.finance"
            onClick={() => gaEventTracker('email')}
          >
            <Button
              text="Contact a Fund Manager"
              className='transparent-btn'
            />
          </a>
          <Button
            text="Earn 15% Interest Now"
            className='secondary no-border'
          />
        </div>
      </section>
    </div>
  )
}


const Feedbacks = ({avatar, name, portfolio, content}: any) => {
  return (
    <div className='feedbacks'>
      <div className="avatar-box">
        <img src={avatar} alt="" className="avatar" />
      </div>
      <div className="feed-main">
        <p className="feed-cont">
          {content}
        </p>
        <p className="who">
          <span>{name}</span>
           <span className="slash">-</span>
          <span>{portfolio}</span>
        </p>
      </div>
    </div>
  )
}

const feedbackArr = [
  {
    name: "Yele Bademosi",
    portfolio: "CEO, Nestcoin",
    avatar: "/icons/yele.svg",
    content: "What Xend Finance is doing with making DeFi available to people of all income levels and technical ability is something that greatly benefits people in countries where fiat currency is unstable."
  },
  {
    name: "Onajite Emerhor",
    portfolio: "Head of Google for Startups Africa",
    avatar: "/icons/onajite.svg",
    content: "As part of our Google Launchpad program, we worked with Xend Finance by mentoring and providing guidance on product, market strategy, leadership, and making Google products available for their team."
  },
  {
    name: " Changpeng Zhao",
    portfolio: "CEO of Binance",
    avatar: "/icons/cz.svg",
    content: "With their platform, they are able to bring stable currency and DeFi investment opportunities to those who normally wouldn’t have them."
  },
]

// "/icons/lady.svg"
