import cogoToast from "cogo-toast"

export const _copyToClipboard = (str: any, message?: any) => {
	const el = document.createElement("textarea");
	el.value = str;
	document.body.appendChild(el);
	el.select();
	document.execCommand("copy");
	document.body.removeChild(el);
	showToast(message || "Copied", "success");

};

export const horizontalScroll = (
	element: HTMLDivElement,
	speed: number,
	distance: number,
	step: number
  ) => {
	let scrollAmount = 0;
	const slideTimer = setInterval(() => {
	  element.scrollLeft += step;
	  scrollAmount += Math.abs(step);
	  if (scrollAmount >= distance) {
		clearInterval(slideTimer);
	  }
	}, speed);
};


export const showToast = (message: string, type?: string) => {
	if (type) type = type.toLowerCase();

	switch (type) {
		case "success":
			cogoToast.success(message, { position: "top-right" });
			break;
		case "info":
			cogoToast.info(message, { position: "top-right" });
			break;
		case "loading":
			cogoToast.loading(message, { position: "top-right" });
			break;
		case "warn":
			cogoToast.warn(message, { position: "top-right" });
			break;
		case "error":
			cogoToast.error(message, { position: "top-right" });
			break;

		default:
			cogoToast.info(message, { position: "top-right" });
			break;
	}
};

