import React from 'react'
import {useNavigate, useLocation} from "react-router-dom"
import { HashLink } from "react-router-hash-link";

type Props = {
    toggleSide?: () => void
}

export const Sidebar = ({toggleSide}: Props) => {
    const navigate = useNavigate()
    const {pathname} = useLocation()

    const [dropdown, setDropdown] = React.useState({open: false, id: ""})
    const openDropdown = (id: any) => setDropdown({open: !dropdown.open, id}) 

    const goToMenu = (route: string) => {
        console.log("route==", route)
        navigate(`${route}`)
        toggleSide && toggleSide()
    }

  return (
    <div className="sidebar">
        <div className="nav">
            <img src="/icons/logo.svg" alt="" className="logo" 
                onClick={() => goToMenu("/")}
            />
            <img src="/icons/close.svg" alt="" className="close" 
                onClick={() => toggleSide && toggleSide()}
            />
        </div>
        <ul className="main-side" >
            <li className="side" onClick={() => goToMenu("/")}>
            <div className="side-top">
                <p className={pathname === "/" ? "side-active" : "inactive" }>Home</p>
                {/* <img src="" alt="" className="menu-img" /> */}
                </div>
            </li>
            <li className={`side ${dropdown.open && dropdown.id === "technology" && "active"}`} >
                <div className="side-top" id="drop-side" 
                    onClick={() => openDropdown("technology")}
                >
                    <p className={pathname === "/developer" ? "side-active" : "inactive" }>Technology</p>
                    <img src={dropdown.open && dropdown.id === "technology"
                            ? "/icons/arrow-up.svg"
                            : "/icons/arrow-down.svg" } 
                        alt="" className="menu-img" 
                    />
                </div>
                {dropdown.open && dropdown.id === "technology" && <TechBox goToMenu={goToMenu} toggleSide={toggleSide} />}
            </li>
            <li className={`side ${dropdown.open && dropdown.id === "products" && "active"}`} >
                <div className="side-top" id="drop-side" 
                    onClick={() => openDropdown("products")}
                >
                    <p className={pathname === "/products" ? "side-active" : "inactive" }>Products</p>
                    <img src={dropdown.open && dropdown.id === "products" 
                        ? "/icons/arrow-up.svg"
                        : "/icons/arrow-down.svg" } 
                    alt="" className="menu-img" 
                    />
                </div>
                {dropdown.open && dropdown.id === "products" && <ProductsBox goToMenu={goToMenu} />}
            </li>
            <li className={`side ${dropdown.open && dropdown.id === "about" && "active"}`}>
                <div className="side-top" id="drop-side"
                    onClick={() => openDropdown("about")}
                >
                    <p className={pathname === "/team" ? "side-active" : "inactive" }>About</p>
                    <img src={dropdown.open && dropdown.id === "about" 
                        ? "/icons/arrow-up.svg"
                        : "/icons/arrow-down.svg" } 
                    alt="" className="menu-img" 
                    />
                </div>
                {dropdown.open && dropdown.id === "about" && <AboutSideBox goToMenu={goToMenu} />}
            </li>
            {/* <li className="side" >
                <div className="side-top">
                <p className={pathname === "/blog" ? "side-active" : "inactive" }>Blog</p>
                </div>
            </li> */}
        </ul>
    </div>
  )
}


const AboutSideBox = ({goToMenu}: any) => {

    return (
        <div className="side-menu-box">
            <div className="sub-item" onClick={() => goToMenu("/news")}>
                <img src="/icons/news.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">News & Announcement</h4>
                <p className="menu-desc">
                    Read all of our press coverage and company news
                </p>
                </div>
            </div>
            <div className="sub-item" onClick={() => goToMenu("/media")}>
                <img src="/icons/media-kit.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">Media Kits</h4>
                <p className="menu-desc">
                Download our logos, colors, and contact info
                </p>
                </div>
            </div>
            <div className="sub-item" onClick={() => goToMenu("/team")}>
                <img src="/icons/team.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">Team</h4>
                <p className="menu-desc">
                Find out more about our international team
                </p>
                </div>
            </div>
            <div className="sub-item" onClick={() => goToMenu("/faq")}>
                <img src="/icons/faq.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">FAQs</h4>
                <p className="menu-desc">
                Everything you need to know on the basics
                </p>
                </div> 
            </div>
            <a href="https://whitepaper.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-item">
                <img src="/icons/litepaper.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">Whitepaper</h4>
                <p className="menu-desc">
                Discover our technology and the vision of Xend Finance (RWA).
                </p>
                </div>
            </a>
    </div>
    )
}

const ProductsBox = ({goToMenu}: any) => {

    return (
        <div className="side-menu-box">
            <div className="sub-item" onClick={() => goToMenu("/mobileapp.html")}>
                <img src="/icons/mobile.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">Mobile App</h4>
                <p className="menu-desc">
                Access your savings and our award-winning technology from your fingertip
                </p>
                </div>
            </div>
            <a href="https://autoyield.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-item">
                <img src="/icons/yield.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">Auto Yield</h4>
                <p className="menu-desc">
                Our high yield aggregator means we automatically scan multiple chains for the highest yields for your savings 
                </p>
                </div>
            </a>
            {/* <a href="https://launchpad.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-item">
                <img src="/icons/Launchpad.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">Xend Launchpad</h4>
                <p className="menu-desc">
                Africa’s first blockchain launchpad to give more exposure and investments to promising startups from the region 
                </p>
                </div>
            </a> */}
            {/* <a href="https://stake.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-item">
                <img src="/icons/staking.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">Xend Staking</h4>
                <p className="menu-desc">
                Staking $XEND tokens gives you some of the best returns in the industry – you choose the lockup period
                </p>
                </div>
            </a> */}
            <a href="https://switchwallet.io/" target="_blank" rel="noopener noreferrer" className="sub-item">
                <img src="/icons/switch.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">SwitchWallet</h4>
                <p className="menu-desc">
                    This service allows developers to create and manage wallets for their users
                </p>
                </div>
            </a>
            <a href="https://www.npmjs.com/package/@xend-finance/web-sdk" target="_blank" rel="noopener noreferrer" className="sub-item">
                <img src="/icons/xend-sdk.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">XEND Finance SDK</h4>
                <p className="menu-desc">
                    Our SDK enables other developers to build on the Xend Finance infractructure.
                </p>
                </div>
            </a>
            <a href="https://xendbridge.com/" target="_blank" rel="noopener noreferrer" className="sub-item">
                <img src="/icons/bridge.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">XendBridge</h4>
                <p className="menu-desc">
                    Enables users convert local currencies to crypto currencies and vice versa.
                </p>
                </div>
            </a>
            <a href="https://madwallet.io/" target="_blank" rel="noopener noreferrer" className="sub-item">
                <img src="/icons/mad-wallet.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">MADWallet</h4>
                <p className="menu-desc">
                    A Secured web3 Multi-chain Wallet extension that allows users to store crypto assets.
                </p>
                </div>
            </a>
            <a href="https://bridge.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-item">
                <img src="/icons/rwa-bridge.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">Native RWA Bridge</h4>
                <p className="menu-desc">
                    Xend Finance native real-world asset token bridge.
                </p>
                </div>
            </a>
            <a href="https://migrate.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-item">
                <img src="/icons/migration-tool.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">RWA Migration Tool</h4>
                <p className="menu-desc">
                    Xend Finance native $XEND to $RWA migration tool.
                </p>
                </div>
            </a>
        </div>
    )
}

const TechBox = ({goToMenu, toggleSide}: any) => {

    const scrollWithOffset = (el: any) => {
        toggleSide && toggleSide()
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
        
    }
    //  onClick={() => toggleSide && toggleSide()}

    return (
        <div className="side-menu-box">
          {/* <div className="sub-item" >
            <img src="/icons/Savings.svg" alt="" className="menu-img" />
            <div className="menu-dets">
              <h4 className="menu-title">Savings</h4>
              <p className="menu-desc">
                It's a start up addicted to debt uptick show me the money capital structure 
              </p>
            </div>
          </div> */}
          <div className="sub-item" onClick={() => goToMenu("/developer")}>
            <img src="/icons/dev-img.svg" alt="" className="menu-img" />
            <div className="menu-dets">
              <h4 className="menu-title">Developer</h4>
              <p className="menu-desc">
              Our award-winning Layer-2 technology is free for developers to build upon
              </p>
            </div>
          </div>
          <div className="sub-item" onClick={() => goToMenu("/business")}>
            <img src="/icons/business-nav.svg" alt="" className="menu-img" />
            <div className="menu-dets">
              <h4 className="menu-title">Business</h4>
              <p className="menu-desc">
              Access DeFi protocols through your own website and gain 15% APY for your customers
              </p>
            </div>
          </div>
          <div className="sub-item" onClick={() => goToMenu("/token")}>
            <img src="/icons/Xend.svg" alt="" className="menu-img" />
            <div className="menu-dets">
                <h4 className="menu-title">$RWA Token</h4>
                <p className="menu-desc">
                    Our token has many different use cases and advantages 
                </p>
            </div>
          </div>
          <a href="https://whitepaper.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-item">
                <img src="/icons/litepaper.svg" alt="" className="menu-img" />
                <div className="menu-dets">
                <h4 className="menu-title">Whitepaper</h4>
                <p className="menu-desc">
                Discover our technology and the vision of Xend Finance (RWA).
                </p>
                </div>
            </a>
        </div>
      )
}
