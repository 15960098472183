import React from 'react'
import { Fade } from "react-reveal";

type Props = {
    icon?: any;
    feature?: string;
    desc?: string
}



export const Feature = ({icon, feature, desc}: Props) => {

    return (
      <Fade bottom duration={2000}>
      <div className="feature">
        <img src={`${icon}`} alt="" className="feat-img" />
        <h3 className="feat">{feature}</h3>
        <p className="feat-desc">{desc}</p>
      </div>
      </Fade>
    )
  }

 

export const SavingBox = ({icon, topic, content}: any) => {
    return (
      <Fade bottom duration={2000}>
        <div className='save-box'>
          <img src={icon} alt="" className="icon" />
          <h4 className="topic">{topic}</h4>
          <p className="content">{content}</p>
        </div>
      </Fade>
    )
}

export const featureData  = [
  {
      icon: "/icons/assets/real-world-assets.svg",
      feature: "Real-world Assets",
      desc: "Invest in real-world assets like stock, real estate etc"
  },
  {
      icon: "/icons/assets/global.svg",
      feature: "A Global Access",
      desc: "Unrestricted membership from around the world."
  },
  {
      icon: "/icons/assets/flexible.svg",
      feature: "Flexible Loans",
      desc: "Access to loans with flexible repayment and terms."
  },
  {
      icon: "/icons/assets/insurance.svg",
      feature: "Insurance",
      desc: "Decentralized insurance to protect assets and investments."
  },
  {
      icon: "/icons/assets/stable.svg",
      feature: "Save in Stable Currencies",
      desc: "Fluctuating and devalued currency is a worry of the past."
  },
  {
    icon: "/icons/assets/earn.svg",
    feature: "Earn Interest and Tokens",
    desc: "Our secure savings earn multiple levels of interest and benefits."
  },
  {
    icon: "/icons/assets/high-interest.svg",
    feature: "High Interest Rates",
    desc: "Up to 15% APY. Traditional interest rates offer 1 – 2% APY"
  },
  {
    icon: "/icons/assets/xend-ai.svg",
    feature: "XEND-AI",
    desc: "Get real-time AI  assistance for smart investment decisions"
  },
  
]

export const savingsArr = [
  {
    icon: "/icons/stable-coins.svg",
    topic: "Save in Stable Currencies",
    content: "Save in multiple stable currencies  - BUSD, USDT and USDC, and earn up to 15% APY. Save towards multiple goals and reach your financial goals faster."
  },
  {
    icon: "/icons/stake-xend.svg",
    topic: "Stake $RWA",
    content: "Earn 30% - 100% APR when you stake $RWA on any of the staking plans. Grow your portfolio with $RWA."
  },
  {
    icon: "/icons/swap-tokens.svg",
    topic: "Swap with Ease",
    content: "Swap between cryptocurrencies easily and at the best rate."
  },
  {
    icon: "/icons/access-loans.svg",
    topic: "Access Loans",
    content: "Enjoy access to low-interest savings secured loans. Users can get a loan in 2 minutes!."
  }
]
