import React from 'react'
import {Navbar} from "./Navbar"
import {Footer} from "./Footer"
import {useLocation, useNavigate} from "react-router-dom"
import { Sidebar } from './Sidebar'
import { RwaLiveBanner } from './RwaLiveBanner'

type Props = {
    children: any
}

export const Layout = ({children}: Props) => {
  const {pathname} = useLocation()
    const [side, setSide] = React.useState(false)
    const toggleSide = () => {
      setSide(!side)
    }

  
  return (
    <div className="layout" >
      <RwaLiveBanner />
      <Navbar toggleSide={toggleSide}  />
      <main className="app-main" >
        {/* <Navbar toggleSide={toggleSide}/> */}
        {/* {pathname !== "/" && */}
         {/* <a href='https://disrupt-africa.com/2022/08/03/nigerias-xend-finance-adds-to-product-suite-of-its-global-crypto-bank/' target="_blank" rel='noreferrer' className="announcement">
            {/* <span id="announce">Announcement:</span> */}
            {/* <span className="add">Nigeria’s Xend Finance adds to product suite of its global crypto bank</span>
            <img src="/icons/arrow-right.svg" alt="" className="arrow" />
        </a>  */}
        {/* } */}
        <main className="children" >
          {children}
        </main>
        <Footer />
      </main>
      <div className={`sider ${side ? "side-open" : "side-close"}`}>
        <Sidebar toggleSide={toggleSide}/>
      </div>
    </div>
  )
}