import React from 'react'
import { Zoom, Fade } from "react-reveal";

type Props = {}

export const Developer = (props: Props) => {
  return (
    <div className="technology">
        <Fade bottom duration={4000}>
        <div className="page-title" id="tech">
            
              <h1 className="topic-1">
                Start Building the Future of Finance Today
              </h1>
            
        </div>

        <div className="clicks">
            <div className="click-1">
                <a href="https://www.npmjs.com/package/@xend-finance/web-sdk" target="_blank" rel="noopener noreferrer" className="dev-btn">
                    <img src="/icons/sdk-download.svg" alt=""  />
                </a>
                <a href="https://docs.xend.finance/" target="_blank" rel="noopener noreferrer" className="dev-btn">
                    <img src="/icons/read-docs.svg" alt=""  />
                </a>
            </div> 
            <a href="https://www.youtube.com/watch?v=V6VoFne7Kzw" target="_blank" rel="noopener noreferrer" className="dev-btn">
                <img src="/icons/watch.svg" alt=""  />
            </a>
        </div>
        </Fade>

        <div className="why-box">
            <h2 className="why">Why Build with Xend SDK?</h2>
            <p className="why-desc">
                Xend Finance has created a highly flexible and robust infrastructure to become a Layer-2 DeFi protocol. Xend Finance has composed multiple Layer-1 DeFi protocols across different blockchains to provide better yields, as opposed to using Layer-1 DeFi yield platforms individually.
            </p>
        </div>

        <div className="cards-sect">
            <Zoom duration={4000} >
            <div className="card">
                <img src="/icons/automated.svg" alt="" className="card-icon" />
                <h4 className="card-title">Automated Yield Aggregator</h4>
                <p className="card-content">
                    The Xend Finance lending protocol aggregator – unlike other DeFi protocols who only list other Layer-1 DeFi protocols – will automatically shift funds across different lending protocols to provide very high yields without any input from the user.
                </p>
            </div>
            </Zoom>

            
            <div className="card">
                <img src="/icons/x-vault.svg" alt="" className="card-icon" />
                <h4 className="card-title">x-Vault system</h4>
                <p className="card-content">
                    The x-vault system is a system that implements multiple yield generation strategies that automatically seek out the highest yields available in DeFi. These x-vaults accept your deposit, then the vault system distributes the funds through multiple strategies, which automatically seek out the best yields (inspired by yearn vaults). Supported Blockchains: BSC and Polygon.
                </p>
            </div>

            <Zoom duration={4000}>
            <div className="card">
                <img src="/icons/governance.svg" alt="" className="card-icon" />
                <h4 className="card-title">Governance</h4>
                <p className="card-content">
                    The Xend Finance governance system will determine strategies that are accepted into the vaults and community members who participate in governance earn a part of the processing fees from the vault operations. The Xend Finance governance system will be deployed across blockchains and users can govern on multiple blockchains.
                </p>
            </div>
            </Zoom>
        </div>

        <section className="build-sect">
            <div className="left">
                <h2 className="start-build">Start Building</h2>
                <p className="build-cont">
                    Our award-winning platform is free for you to build on and scale, saving you time and resources
                </p>
                <a href="https://www.npmjs.com/package/@xend-finance/web-sdk" target="_blank" rel="noopener noreferrer" className="sdk">
                    <img src="/icons/sdk-btn.svg" alt="" />
                </a>
            </div>
            <div className="right">
                <img src="/icons/vscode.svg" alt="" className="code" />
            </div>
        </section>

        <section className="dev-resources">
            <h2 className="resources">Developer Resources</h2>
            <div className="min-cards">
                <div className="card">
                    <a href="https://docs.xend.finance/" target="_blank" rel="noopener noreferrer" className='card-link'>
                        <h4 className="title-min">Documentation</h4>
                        <p className="min-cont">
                            Download our e-book guide to learn how it works.
                        </p>
                        <img src="/icons/arrow-right.svg" alt="" className="arr" />
                    </a>
                </div>
                <div className="card">
                    <a href="https://t.me/XendFinanceDevs" target="_blank" rel="noopener noreferrer" className='card-link'>
                        <h4 className="title-min">Join Community</h4>
                        <p className="min-cont">
                            Join Xend Finance Developer Community.
                        </p>
                        <img src="/icons/arrow-right.svg" alt="" className="arr" />
                    </a>
                </div>
                <div className="card">
                    <a href="https://www.youtube.com/watch?v=V6VoFne7Kzw" target="_blank" rel="noopener noreferrer" className='card-link'>
                        <h4 className="title-min">Project Example</h4>
                        <p className="min-cont">
                            Check out the winners from our Hackathon.
                        </p>
                        <img src="/icons/arrow-right.svg" alt="" className="arr" />
                    </a>
                </div>
            </div>
        </section>
        
    </div>
  )
}
