import { FC } from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	text: string | HTMLElement | JSX.Element;
	loading?: boolean;
	className?: string;
}

export const Button: FC<ButtonProps> = ({ text, loading, className, ...rest }) => {
	return (
		<button {...rest} disabled={loading || rest.disabled} 
			className={`button ${className && className}`}
		>
			<div className="inner">
				<p>{text}</p>
			</div>
		</button>
	);
};
