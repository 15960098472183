import { Button } from "components/Button"
import highInterest from "assets/high-interest.svg"

export const featuredata  = [
    {
        icon: {highInterest},
        feature: "High Interest Rates",
        desc: "Up to 15% APY. Traditional interest rates offer 1 – 2% APY"
    },
    {
        icon: "../assets/global.svg",
        feature: "A Global Access",
        desc: "unrestricted membership from around the world."
    },
    {
        icon: "../assets/flexible.svg",
        feature: "Flexible Loans",
        desc: "Access to loans with flexible repayment and terms."
    },
    {
        icon: "../assets/insurance.svg",
        feature: "Insurance",
        desc: "Decentralized insurance to protect assets and investments."
    },
    {
        icon: "../assets/stable.svg",
        feature: "Save in Stable Currencies",
        desc: "Fluctuating and devalued currency is a worry of the past."
    },
    // {
    //     icon: "../assets/earn.svg",
    //     feature: "Earn Interest and Tokens",
    //     desc: "our secure savings earn multiple levels of interest and benefits."
    // },
]

export const faqData = [
    {
        question: "What is DeFi?",
        answer: `DeFi includes a range of decentralized financial instruments and mechanisms. These include stable coins, lending protocols, security tokens, derivatives, exchanges, and more. Many DeFi systems enable liquidity providers to earn interest on their collateral via a variety of different reward mechanisms`
    },
    {
        question: "What is Staking?",
        answer: `
        Staking cryptocurrency means you are holding cryptocurrency to verify transactions and support the network. In exchange for holding the crypto and strengthen the network, you will receive a reward. You can also call the reward an interest. With staking you can generate a passive income by holding coins. Besides that you receive a reward, you can earn extra when the coin increases in value
        `
    },
    {
        question: "How can I save and stake at the same time?",
        answer: `Your savings are staked automatically on Other DeFi platforms using instruments that give the best returns. You don't have to do anything. It is automatically handled by the platform.`
    },
    {
        question: "How does Xend Finance benefit from being on Google Launchpad?",
        answer: `Xend Finance is Africa’s first DeFi project, allowing users to earn compound interest and high APYs on their savings. Being featured in the Google Launchpad Accelerator program is massive for a startup and being one of the few startups focused on DeFi and Credit Unions is just a huge opportunity. Not only does Google validate and back the core mission and values of the project, but it also gives our team access to Google’s core foundations — its people, network, and advanced technologies.`
    },
    {
        question: "How does Xend Finance utilize the funds that users deposit on the platform?",
        answer: `Xend Finance’s smart contract periodically invests user funds on leading DeFi lending protocols, such as Compound, AAVE, dYdX and Yearn Finance.

        75% of the interest generated from DeFi lending networks is paid to the Credit Union’s overall savings deposited, and 50% of the interest generated from Compound is deposited to the Individual Saving Node (ISN). `
    },
    // {
    //     question: "What benefits does Xend Finance’s native token $XEND provide to holders?",
    //     answer: `The native token of the Xend Finance ecosystem is the Xend Token ($XEND), which is used to reward users for saving, investing and performing different operations on the network.
    //             <br/>
    //             $XEND tokens also generate interest for the users - the Xend Finance smart contract periodically invests user savings on DeFi lending protocols, which produces a second level of interest for the users.
    //         `
    // },
    {
        question: "Why is Xend Finance building on the Binance Smart Chain?",
        answer: `Binance Smart Chain offers a powerful DeFi ecosystem and a reliable alternative to Ethereum. Since its launch in 2020, its meteoric rise and widespread adoption have only validated its capabilities of being robust, cheap, fast, and user-friendly — ideal platform features for DeFi and DApp development.`
    },
]
// <a href="http://google.com" target="blank">Sign up here</a> to join the hackathon as a developer and as a workshop participant.

export const homePressCoverage = [
    {
        headline: "Africa’s first global blockchain bank hits 100k users",
        summary: "Billed as the first global blockchain bank out of Africa, Xend Finance has just clocked 100 000 users on its savings platform while further opening it to local currencies in Ghanaian Cedis and Kenyan Shillings.",
        link: "https://ventureburn.com/2022/06/africas-first-global-blockchain-bank-hits-100k-users/",
        date: "June 29, 2022",
        platform: "ventureburn"
    },
    {
        headline: "Xend Finance Celebrate One Year Anniversary",
        summary: "Marketing endeavours by the team have helped see Xend Finance enjoy important growth in the last few months, as the project sets an ambitious marker for the coming year.",
        link: "https://www.bsc.news/post/xend-finance-celebrate-one-year-anniversary",
        date: "April 13, 2022",
        platform: "BSC News"
    },
]

// {
    //   headline: "",
    //  summary: "",
    //  link: "",
    //  date: "",
    // platform
    // },

export const newsArray = [
    {
        headline: "Xend Finance Partners With GT Protocol To Power XEND-AI And Assist Our Users Make Better Investment",
        summary: "Xend Finance has announced a partnership to integrate the GT AI investment API & SDK into XEND-AI, enabling investment actions for all Xend Finance product users.",
        link: "https://medium.com/xendfinance/xend-finance-partners-with-gt-protocol-to-power-xend-ai-and-assist-our-users-make-better-investment-41f902baddc8",
        date: "December 15, 2023",
        platform: "Medium"
    },
    {
        headline: "The MADWallet launch airdrop giveaway is still on!",
        summary: "You can win up to 1,000,000 $XEND🤯 just by interacting with MADWallet.",
        link: "https://coinmarketcap.com/community/articles/64132555450b951d398b5e29/",
        date: "March 24, 2023",
        platform: "Coin Market Cap"
    },
    {
        headline: "Nigeria’s Xend Finance adds to product suite of its global crypto bank",
        summary: "The Nigeria-based Xend Finance, a global crypto bank, has announced the launch of XendBridge, a crypto payment API, and SwitchWallet, a free public wallet infrastructure.",
        link: "https://disrupt-africa.com/2022/08/03/nigerias-xend-finance-adds-to-product-suite-of-its-global-crypto-bank/",
        date: "August 3, 2022",
        platform: "Disrupt Africa"
    },
    {
        headline: "Xend Finance to help Businesses with New Crypto Payment System",
        summary: "Xend Finance has launched a Crypto Payment API and wallet infrastructure to aid access of crypto-related services to users.",
        link: "https://cryptotvplus.com/2022/07/xend-finance-to-help-businesses-with-new-crypto-payment-system/",
        date: "July 29, 2022",
        platform: "Cryptotvplus"
    },
    {
        headline: "Xend launches crypto payment API and wallet infrastructure",
        summary: "Xend Finance, an Africa-founded global crypto bank, announced the launch of payment API XendBridge and SwitchWallet, a free public wallet infrastructure.",
        link: "https://ventureburn.com/2022/07/xend-launches-crypto-payment-api-and-wallet-infrastructure/",
        date: "July 29, 2022",
        platform: "Ventureburn"
    },
    {
        headline: "Xend Expands Reach in Africa With New Products",
        summary: "The new products will boost the scaling capacity of African startups, and potentially improve Africa's economy as a whole.",
        link: "https://www.bsc.news/post/xend-expands-reach-in-africa-with-new-products",
        date: "July 29, 2022",
        platform: "BSC News"
    },
    {
        headline: "Introducing XendBridge API and SwitchWallet Business Wallet Infrastructure by Nigeria’s Xend Finance",
        summary: "Xend Finance, an African DeFi platform, has announced the launch of XendBridge, a crypto payment API, and SwitchWallet, a free public wallet infrastructure.",
        link: "https://bitcoinke.io/2022/07/introducing-xendbridge-api-and-switchwallet-infrastructure/",
        date: "July 27, 2022",
        platform: "BitKE"
    },
    {
        headline: "Xend Hits Milestone Upon Launch Into Two New African Nations",
        summary: "The global acceptance of Xend Finance grows as it crosses 100k users. In addition, the Xend Finance app is made accessible to people in Kenya and Ghana who want to convert their local currency into stable cryptocurrency and save.",
        link: "https://www.bsc.news/post/xend-hits-milestone-upon-launch-into-two-new-african-nations-2",
        date: "July 2, 2022",
        platform: "BSC News"
    },
    {
        headline: "Burgeoning crypto start-up, Xend finance launch in Ghana, Kenya",
        summary: "Global crypto bank specialized in serving underserved regions, Xend Finance has announced its launch in Kenya and Ghana. The launch means that locals in the two African countries can now save crypto in their local currencies, Ghanaian Cedis and Kenyan Shillings.",
        link: "https://www.cryptopolitan.com/start-up-xend-finance-launch-in-ghana-kenya/",
        date: "July 1, 2022",
        platform: "Cryptopolitan"
    },
    {
        headline: "How Xend Finance CEO, Ugochukwu Aronu, went from chasing oil money to discovering blockchain gold",
        summary: "Ugochukwu Aronu, CEO and Co-founder of Xend Finance, grew up in a time when working in an oil company or a bank was perceived as the fastest way to become wealthy. But after seeing that some of the wealthiest people in the world were into tech, he knew he had to jump ship.",
        link: "https://techpoint.africa/2022/06/30/ugochukwu-aronu-blockchain",
        date: "June 30, 2022",
        platform: "Techpoint Africa"
    },
    {
        headline: "Africa’s first global blockchain bank hits 100k users",
        summary: "Billed as the first global blockchain bank out of Africa, Xend Finance has just clocked 100 000 users on its savings platform while further opening it to local currencies in Ghanaian Cedis and Kenyan Shillings.",
        link: "https://ventureburn.com/2022/06/africas-first-global-blockchain-bank-hits-100k-users/",
        date: "June 29, 2022",
        platform: "Ventureburn"
    },
    {
        headline: "Xend Finance Celebrate One Year Anniversary",
        summary: "Marketing endeavours by the team have helped see Xend Finance enjoy important growth in the last few months, as the project sets an ambitious marker for the coming year.",
        link: "https://www.bsc.news/post/xend-finance-celebrate-one-year-anniversary",
        date: "April 13, 2022",
        platform: "BSC News"
    },
    {
        headline: "50,000 people now use ‘Xend Finance’, Nigerian Crypto Bank",
        summary: "Nigerian Crypto bank, Xend Finance has announced that over 50-thousand people now use their savings platform in the country.",
        link: "https://www.vanguardngr.com/2022/04/50000-people-now-use-xend-finance-nigerian-crypto-bank/",
        date: "April 17, 2022",
        platform: "Vanguard"
    },
    {
        headline: "Africa’s credit unions are tapping into fintech for growth",
        summary: "From table banking to credit unions, the savings and credit cooperative organization (Sacco) sector in Africa is fast digitizing to bring financial inclusion to all Sacco members.",
        link: "https://qz.com/africa/2114103/fintech-offers-growth-opportunities-for-africas-saccos/",
        date: "January 18, 2022",
        platform: "Quartz Africa"
    },
    {
        headline: "Introducing Xend Launchpad – The First African-focused Launchpad for African Blockchain Projects by Xend Finance",
        summary: "In an effort to help discover and identify blockchain-based projects at an early stage, Xend Finance, one of the leading DeFi projects from Africa, has announced the launch of the Xend Launchpad.",
        link: "https://bitcoinke.io/2021/10/introducing-xend-launchpad/",
        date: "October 21, 2021",
        platform: "Bitcoinke"
    },
    {
        headline: "Xend Finance: Google Africa, BSC join Africa’s largest hackathon",
        summary: "Dubbed “Decentralising Africa and Beyond”, the first Xend Finance DeFi Hackathon is focused on promoting the dreams and visions of blockchain and cryptocurrency as well as decentralizing the financial sector, invariably giving the masses financial freedom.",
        link: "https://www.vanguardngr.com/2021/10/xend-finance-google-africa-bsc-join-africas-largest-hackathon/",
        date: "October 18, 2021",
        platform: "Vanguard"
    },
    {
        headline: "NIGERIAN FINTECH, XEND, HAS LAUNCHED A BUSINESS ASSISTANT TOOL FOR SMES NAMED GEENA",
        summary: "Geena was developed out of the understanding that running a business can be complex, and the intention is to make the processes involved, cool and interesting.        ",
        link: "https://venturesafrica.com/nigerian-fintech-xend-has-launched-a-business-assistant-tool-for-smes-named-geena/",
        date: "June 18, 2021",
        platform: "Ventures Africa"
    },
    {
        headline: "Most Promising Projects to Be Listed on PancakeSwap Exchange Soon",
        summary: "The emergence of new projects is usually preplanned with potential target markets in mind. While Africa is often seen as an untapped market, China is arguably the right region for massive scaling.",
        link: "https://www.coinspeaker.com/projects-pancakeswap-soon/",
        date: "June 16 2021",
        platform: "Coinspeaker"
    },
    {
        headline: "Nigerians Turn to Stablecoins to Protect Against Inflation",
        summary: "Many Nigerians are starting to explore stablecoins as a viable solution against inflation. In fact, Nigeria-based DeFi protocol Xend Finance is one of the frontrunners in this aspect.",
        link: "https://coinquora.com/nigerians-turn-to-stablecoins-to-protect-against-inflation/",
        date: "March 17, 2021",
        platform: "Coinquora"
    },
    {
        headline: "A Chat with Ugochukwu Aronu, CEO, Xend Finance",
        summary: "Xend Finance, the first African DeFi credit union platform, recently launched on the Binance Smart Chain (BSC) while also raising over $2 million dollars.",
        link: "https://bitcoinke.io/2021/03/podcast-xend-finance-with-aronu/",
        date: "March 13, 2021",
        platform: "Bitcoinke"
    },
    {
        headline: "Nigerian DeFi startup Xend Finance increases size of funding round to $2m",
        summary: "Nigeria’s Xend Finance, a decentralised finance (DeFi) platform for credit unions and cooperatives, has topped up its funding round to US$2 million as it announces a key partnership.",
        link: "https://disrupt-africa.com/2021/02/10/nigerian-defi-startup-xend-finance-increases-size-of-funding-round-to-2m/",
        date: "FEBRUARY 10, 2021",
        platform: "Disrupt Africa"
    },
    {
        headline: "Binance-Backed DeFi Credit Union Platform Secures Partners, Funding Before Launch",
        summary: "Xend Finance, a Nigeria-based decentralized finance (DeFi) platform for credit unions and cooperatives, has secured new partners and funding as it prepares for its bid to bring high-interest savings opportunities to Africa.",
        link: "https://www.coindesk.com/business/2021/02/09/binance-backed-defi-credit-union-platform-secures-partners-funding-before-launch/",
        date: "Feb 9, 2021",
        platform: "Coindesk"
    },

    // {
    //     headline: "",
    //     summary: "",
    //     link: "",
    //     date: ""
    // },
] 


// {
//     headline: "Binance-backed Xend Finance launches DeFi platform for credit unions in Africa",
//     summary: "Nigerian startup Xend Finance uses decentralized finance (DeFi) to address currency devaluation. DeFi aims to bridge the gap between decentralized blockchains and financial services.",
//     link: "https://techcrunch.com/2021/03/22/binance-backed-xend-finance-launches-defi-platform-for-credit-unions-in-africa/?guccounter=1&guce_referrer=aHR0cHM6Ly94ZW5kLmZpbmFuY2Uv&guce_referrer_sig=AQAAALRahDN8Nxf_dfqaBZPufKfWug2XHi9pyZWOIkamHunFxPwKb0mc1Sz-cGcYRFa6CB6jY8fhaJYoClkWh2LC4Qu9HxMVSU3s7vy4_64ZrkGC5SX9MZkXAzmLN-pCkdkkb6dCO5hP3QeyeOHkL9xa-NTrTQl8HU-CZLwk5VYX9Nq5",
//     date: "March 22, 2021",
//     platform: "Techcrunch"
// },
// {
//     headline: "DeFi Credit Union Platform Xend Finance Goes Live in Africa First",
//     summary: "A Nigeria-based decentralized finance (DeFi) platform that allows users to pool capital for the creation of their own credit unions has launched its mainnet. Backed by Binance, Google...",
//     link: "https://www.coindesk.com/business/2021/03/22/defi-credit-union-platform-xend-finance-goes-live-in-africa-first/",
//     date: "March 22, 2021",
//     platform: "Coindesk"
// },
// {
//     headline: "Xend Finance is Bringing DeFi Services to Africa",
//     summary: "Xend Finance today launched a platform to enable credit unions and co-ops throughout Africa to provide DeFi services to their members. Xend Finance CEO Ugochukwu Aronu told Decrypt...",
//     link: "https://decrypt.co/62369/xend-finance-is-bringing-defi-services-to-africa",
//     date: "March 22, 2021",
//     platform: "Decrypt"
// },