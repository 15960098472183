import {useState, useRef, useEffect} from 'react'
import { useNavigate, useLocation } from 'react-router'
import { Link } from "react-scroll"; 
// import onClickOutside from "react-onclickoutside";
// import { HashLink } from "react-router-hash-link";
import {useOnClickOutside} from "customHooks"


interface Props {
  toggleSide?: any
  navDrop?: boolean;
  toggleDrop?: () => void;
}

export const Navbar = ({toggleSide}: Props) => {
  const navigate = useNavigate()
  const {pathname} = useLocation()

  const menuRef = useRef(null);

  const [drop, setDrop] = useState({open: false, id: ""})
  // const dropDown = (id: string) => setDrop({open: !drop.open, id: id})
  useOnClickOutside(menuRef, () => {
    setDrop({open: false, id: ""})
  });
  

  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }

  return (
    <nav id="navbar">
      <div className="nav-lg">
        <img src="/icons/logo.svg" alt="logo" className="logo" 
          onClick={() => navigate("/")}
        />
        <ul className="menu">
          <li className="nav-li" onClick={() => navigate("/")}>
            <p className="nav-p">Home</p>
            <img src="/icons/active-nav.svg" alt="" 
              className={pathname === "/" ? "show" : "hide"} 
            />
          </li>

          <li className="nav-li" 
            onClick={() => {
              if (drop.open === true) {
                return setDrop({open: false, id: ""})
              }
              else setDrop({open: true, id: "technology"})
            }}
          >
            <p className="nav-p">
              <span>Technology</span>
              <img 
                src={drop.open === true && drop.id === "technology" ? "/icons/arrow-up.svg" : "/icons/nav-dropdown.svg"} 
                alt="" 
              />
            </p>
            <img src="/icons/active-nav.svg" alt="" 
              className={pathname === "/developer" || pathname === "/business" ? "show" : "hide"} 
            />
            {drop.open && drop.id === "technology" && <Technology menuRef={menuRef} />}
          </li>

          <li className="nav-li" 
            onClick={() => setDrop({open: !drop.open, id: "products"})}
          >
            <p className="nav-p">
              <span>Products</span>
              <img 
                src={drop.open === true && drop.id === "products" ? "/icons/arrow-up.svg" : "/icons/nav-dropdown.svg"} 
                alt="" 
              />
            </p>
            <img src="/icons/active-nav.svg" alt="" 
              className={pathname === "/mobileapp.html" ? "show" : "hide"} 
            />
            {drop.open && drop.id === "products" && <Products menuRef={menuRef} />}
          </li>

          <li className="nav-li" id="link">
            <Link
              activeClass="active"
              to="roadmap"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="link-menu"
            >
            {/* <HashLink 
              className="link-menu"
              smooth to="#roadmap" 
              scroll={(el: any) => scrollWithOffset(el)}
            > */}
              <p className="nav-p" style={{marginTop: '5px'}}>RoadMap</p>
            {/* </HashLink> */}
            </Link>
          </li>

          <li className="nav-li" onClick={() => navigate("/")}>
            <a href="http://learn.xend.finance" target="_blank" rel="noopener noreferrer" className="nav-p">
              Learn
            </a>
            <img src="/icons/active-nav.svg" alt="" 
              className={pathname === "/learn" ? "show" : "hide"} 
            />
          </li>
          
          <li className="nav-li" 
            onClick={() => setDrop({open: !drop.open, id: "about"})}
          >
            <p className="nav-p">
              About
              <img 
                src={drop.open === true && drop.id === "about" ? "/icons/arrow-up.svg" : "/icons/nav-dropdown.svg"} 
                alt="" 
              />
              </p>
            <img src="/icons/active-nav.svg" alt="" 
              className={
              pathname === "/team" || pathname === "/faq" || pathname === "/media" || pathname === "/news"
               ? "show" : "hide"} 
            />
            {drop.open && drop.id === "about" && <AboutMenu menuRef={menuRef} />}
          </li>
          {/* <li className="nav-li" >
            <div className="nav-p">Blog</div>
            <img src="/icons/active-nav.svg" alt="" 
              className={pathname === "/blog" ? "show" : "hide"} 
            />
          </li> */}
          {/* <li className="nav-li">
            <img src="/icons/vertical.svg" alt="" className="vertical" />
          </li>
          <li className="nav-li" id="eng">
            <div className="nav-p">Eng</div>
            <img src="/icons/arrow-down.svg" alt=""/>
          </li> */}
        </ul>
        
      </div>
      <div className="nav-sm">
        <img src="/icons/logo.svg" alt="logo" className="logo" 
          onClick={() => navigate("/")}
        /> 
        <img src="/icons/hamburger.svg" alt="logo" className="hamburger" 
          onClick={() => toggleSide && toggleSide()}
        />
      </div>
    </nav>
  )
}

export const Technology = ({menuRef}: any) => {
  const navigate = useNavigate()

  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }


  return (
    <div className="show-menu-box" id="tech-menu"
      ref={menuRef} 
    >
      {/* <div className="sub-menu" >
        <img src="/icons/Savings.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">Savings</h4>
          <p className="menu-desc">
            Your stable savings earns the highest interest in the industry
          </p>
        </div>
      </div> */}
      <div className="sub-menu" onClick={() => navigate("/developer")}>
        <img src="/icons/dev-img.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">Developer</h4>
          <p className="menu-desc">
          Our award-winning Layer-2 technology is free for developers to build upon
          </p>
        </div>
      </div>
      <div className="sub-menu" onClick={() => navigate("/business")}>
        <img src="/icons/business-nav.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">Business</h4>
          <p className="menu-desc">
          Access DeFi protocols through your own website and gain 15% APY for your customers
          </p>
        </div>
      </div>
      <div className="sub-menu" onClick={() => navigate("/token")}>
        <img src="/icons/Xend.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">$RWA Token</h4>
          <p className="menu-desc">
            Our token has many different use cases and advantages 
          </p>
        </div>
      </div>
      <a href="https://whitepaper.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-menu">
        <img src="/icons/litepaper.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">Whitepaper</h4>
          <p className="menu-desc">
          Discover our technology and the vision of Xend Finance (RWA).
          </p>
        </div>
      </a>
    </div>
  )
}

export const Products = ({menuRef}: any) => {
  const navigate = useNavigate()
  
  return (
    <div className="show-menu-box" id="product-menu" ref={menuRef}>
      <div className="sub-menu" onClick={() => navigate("/mobileapp.html")}>
        <img src="/icons/mobile.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">Mobile App</h4>
          <p className="menu-desc">
          Access your savings and our award-winning technology from your fingertip
          </p>
        </div>
      </div>
      <a href="https://autoyield.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-menu">
        <img src="/icons/yield.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">Auto Yield</h4>
          <p className="menu-desc">
          Our high yield aggregator means we automatically scan multiple chains for the highest yields for your savings 
          </p>
        </div>
      </a>
      {/* <a href="https://launchpad.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-menu">
        <img src="/icons/Launchpad.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">Xend Launchpad</h4>
          <p className="menu-desc">
          Africa’s first blockchain launchpad to give more exposure and investments to promising startups from the region 
          </p>
        </div>
      </a> */}
      {/* <a href="https://stake.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-menu">
        <img src="/icons/staking.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">XEND Staking</h4>
          <p className="menu-desc">
            Staking $XEND tokens gives you some of the best returns in the industry – you choose the lockup period 
          </p>
        </div>
      </a> */}
      <a href="https://switchwallet.io/" target="_blank" rel="noopener noreferrer" className="sub-menu">
        <img src="/icons/switch.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">SwitchWallet</h4>
          <p className="menu-desc">
          This service allows developers to create and manage wallets for their users
          </p>
        </div>
      </a>
      <a href="https://www.npmjs.com/package/@xend-finance/web-sdk" target="_blank" rel="noopener noreferrer" className="sub-menu">
        <img src="/icons/xend-sdk.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">XEND Finance SDK</h4>
          <p className="menu-desc">
          Our SDK enables other developers to build on the Xend Finance infrastructure.
          </p>
        </div>
      </a>
      <a href="https://xendbridge.com/" target="_blank" rel="noopener noreferrer" className="sub-menu">
        <img src="/icons/bridge.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">XendBridge</h4>
          <p className="menu-desc">
          Enables users convert local currencies to crypto currencies and vice versa. 
          </p>
        </div>
      </a>
      <a href="https://madwallet.io/" target="_blank" rel="noopener noreferrer" className="sub-menu">
        <img src="/icons/mad-wallet.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">MADWallet</h4>
          <p className="menu-desc">
          A Secured web3 Multi-chain Wallet extension that allows users to store crypto assets.
          </p>
        </div>
      </a>
      <a href="https://bridge.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-menu">
        <img src="/icons/rwa-bridge.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">Native RWA Bridge</h4>
          <p className="menu-desc">
          Xend Finance native real-world asset token bridge. 
          </p>
        </div>
      </a>
      <a href="https://migrate.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-menu">
        <img src="/icons/migration-tool.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">RWA Migration Tool</h4>
          <p className="menu-desc">
          Xend Finance native $XEND to $RWA migration tool.
          </p>
        </div>
      </a>
    </div>
  )
}

export const AboutMenu = ({menuRef}: any) => {
  const navigate = useNavigate()
  
  return (
    <div className="show-menu-box" id="about-menu" ref={menuRef}>
      <div className="sub-menu" id="link" onClick={() => navigate("/news")}>
        <img src="/icons/news.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">News & Announcements</h4>
          <p className="menu-desc">
          Read all of our press coverage and company news
          </p>
        </div>
      </div>
      <div className="sub-menu" onClick={() => navigate("/media")} >
        <img src="/icons/media-kit.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">Media Kits</h4>
          <p className="menu-desc">
            Download our logos, colors, and contact info
          </p>
        </div>
      </div>
      <div className="sub-menu" onClick={() => navigate("/team")}>
        <img src="/icons/team.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">Team</h4>
          <p className="menu-desc">
          Find out more about our international team
          </p>
        </div>
      </div>
      <div className="sub-menu" onClick={() => navigate("/faq")}>
        <img src="/icons/faq.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">FAQs</h4>
          <p className="menu-desc">
          Everything you need to know on the basics
          </p>
        </div> 
      </div>
      <a href="https://whitepaper.xend.finance/" target="_blank" rel="noopener noreferrer" className="sub-menu">
        <img src="/icons/litepaper.svg" alt="" className="menu-img" />
        <div className="menu-dets">
          <h4 className="menu-title">Whitepaper</h4>
          <p className="menu-desc">
          Discover our technology and the vision of Xend Finance (RWA).
          </p>
        </div>
      </a>
    </div>
  )
}
