import React, {useRef} from "react"
import { Fade } from "react-reveal";
import { Button } from 'components'
import {useNavigate} from "react-router-dom"
import { HashLink } from "react-router-hash-link";
import {useOnClickOutside} from "customHooks"

type Props = {}


export const Footer = (props: Props) => {
  const navigate = useNavigate()

  const [social, setSocial] = React.useState(false)

  const footRef = useRef(null);
  useOnClickOutside(footRef, () => {
    setSocial(false)
  });

  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }

  return (
    <>
     <section className="comms">
      <Fade bottom duration={1500}>
        <p className="join">Join Our Communities</p>
        <p className="first">Be the first to learn about company updates, product launches, and giveaways.</p>
        <div className="socials">
          <div className="social" onClick={() => setSocial(!social)}>
            <div>
              <img src="/icons/socials/telegram.svg" alt="" />
              <span>Telegram</span>
              <img src="/icons/socials/Chevron.svg" alt="" className='social-arrow'/>
              {social && <TelegramDropdown footRef={footRef} />}
            </div>
            
          </div>
          <a href="http://twitter.com/xendfinance" target="_blank" rel="noopener noreferrer" className="social">
            <div>
              <img src="/icons/socials/twitter.svg" alt="" />
              <span>Twitter</span>
              {/* <img src="/icons/socials/Chevron.svg" alt="" /> */}
            </div>
          </a>
          <a href="https://www.youtube.com/channel/UCcR2lTpYwCws-axra4AAO8Q/featured" target="_blank" rel="noopener noreferrer" className="social">
            <div>
              <img src="/icons/socials/youtube.svg" alt="" />
              <span>Youtube</span>
            </div>
          </a>
          <a href="http://www.reddit.com/r/XendFinance/" target="_blank" rel="noopener noreferrer" className="social">
            <div>
              <img src="/icons/socials/reddit.svg" alt="" />
              <span>Reddit</span>
            </div>
          </a>
          <a href="https://discord.gg/QGHb7jp2GV" target="_blank" rel="noopener noreferrer" className="social">
            <div>
              <img src="/icons/socials/discord.svg" alt="" />
              <span>Discord</span>
            </div>
          </a>
          <a href="http://medium.com/xendfinance/" target="_blank" rel="noopener noreferrer" className="social">
            <div>
              <img src="/icons/socials/medium.svg" alt="" />
              <span>Medium</span>
            </div>
          </a>
          <a href="http://web.facebook.com/XendFinance" target="_blank" rel="noopener noreferrer" className="social">
            <div>
              <img src="/icons/socials/facebook.svg" alt="" />
              <span>Facebook</span>
            </div>
          </a>
          <a href="https://github.com/xendfinance" target="_blank" rel="noopener noreferrer" className="social">
            <div>
              <img src="/icons/socials/github.svg" alt="" />
              <span>Github</span>
            </div>
          </a>
          {/* <a href="http://t.me/xendFinance" target="_blank" rel="noopener noreferrer" >
            <img src="/icons/telegram.svg" alt="" className="social" />
          </a>
          <a href="http://twitter.com/xendfinance" target="_blank" rel="noopener noreferrer" >
            <img src="/icons/twitter.svg" alt="" className="social" />
          </a>
          <a href="https://www.youtube.com/channel/UCcR2lTpYwCws-axra4AAO8Q/featured" target="_blank" rel="noopener noreferrer" >
            <img src="/icons/youtube.svg" alt="" className="social" />
          </a>
          <a href="http://www.reddit.com/r/XendFinance/" target="_blank" rel="noopener noreferrer" >
            <img src="/icons/redditt.svg" alt="" className="social" />
          </a>
          <a href="https://discord.io/xendfinance" target="_blank" rel="noopener noreferrer" >
            <img src="/icons/discord.svg" alt="" className="social" />
          </a>
          <a href="http://medium.com/xendfinance/" target="_blank" rel="noopener noreferrer" >
            <img src="/icons/medium.svg" alt="" className="social" />
          </a>
          <a href="http://web.facebook.com/XendFinance" target="_blank" rel="noopener noreferrer" >
            <img src="/icons/facebook.svg" alt="" className="social" />
          </a> */}
        </div> 
      </Fade>
            <div className="pre-footer">
              <h2 className="get-started">Get Started</h2>
              <div className="remain">
                
                <div className="feet-btns">
                  {/* <a href="http:/google.com/" target="_blank" rel="noopener noreferrer" className='save'>
                    <img src="/icons/start-saving.svg" alt="" className="save" />
                  </a> */}
                  <a href={process.env.REACT_APP_XEND_PLAYSTORE_URL} target="_blank" rel="noopener noreferrer" className='save'>
                    <img src="/icons/google.svg" alt="" />
                  </a>
                  <a href={process.env.REACT_APP_XEND_APPSTORE_URL} target="_blank" rel="noopener noreferrer" className='save'>
                    <img src="/icons/apple.svg" alt="" />
                  </a>
                  <a href={process.env.REACT_APP_XEND_APK_URL} target="_blank" rel="noopener noreferrer" className='save'>
                    <img src="/icons/apk.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
        </section>
    <footer>
      <div className="foot-top">
      <div className="foot-left">
        <img src="/icons/logo-white.svg" alt="" className="logo-ft" 
          onClick={() => navigate("/")}
        />
        <p className="newsletter">Get our Monthtly Newsletters</p>
        <a href="https://xendfinance.substack.com/p/coming-soon?s=r" target="_blank" rel="noopener noreferrer">
        <Button
          text="Subscribe"
          className="secondary no-border"
          id="footer-subscribe-btn"
        />
        </a>
        
        <div className="foot-box">
          <a href='https://medium.com/tidal-finance/xend-finance-buys-1-million-worth-of-coverage-tvl-through-tidal-finance-12648b604d08' target="_blank" rel="noopener noreferrer" className="assurances" >
              <h5 className="title">Insured for up to $300 Million by</h5>
              <img src="/icons/tidal.svg" alt="" className="who" />
          </a>
          <div className="assurances">
            <h5 className="title">Audited by</h5>
            <a href='https://www.certik.com/projects/xendfinance' target="_blank" rel="noopener noreferrer" className='auditors' >
                <img src="/icons/certik.svg" alt="" className="who" />
            </a>
            <a href='https://techrate.org/' target="_blank" rel="noopener noreferrer" className='auditors' >
                <img src="/icons/techrate-foot.svg" alt="" className="who" />
            </a>
            <a href='https://applicature.com/' target="_blank" rel="noopener noreferrer" className='auditors' >
                <img src="/icons/Applicature-foot.svg" alt="" className="who" />
            </a>
            <a href='https://omniscia.io/' target="_blank" rel="noopener noreferrer" className='auditors' > 
                <img src="/icons/Omniscia-foot.svg" alt="" className="who" />
            </a>
          </div>
          
        </div>
      </div>
      <div className="foot-right">
        <div className="tech">
          <div className="main">
            <h4 className="topic">Technology</h4>
            <ul className="others">
              {/* <li className="ft" onClick={() => navigate("/")}>Savings</li> */}
              <li className="ft" onClick={() => navigate("/business")}>Business</li>
              <li className="ft" onClick={() => navigate("/developer")}>Developer</li>
            </ul>
          </div>

          {/* <div className="main">
            <h4 className="topic">Quick Links</h4>
            <ul className="others">
              <li className="ft">
                <a href="https://hackathon.xend.finance/" target="_blank" rel="noopener noreferrer" >
                  Hackathon 2021
                </a>
              </li>
              <li className="ft">
                <a href="https://xend.finance/" target="_blank" rel="noopener noreferrer" >
                  Apply for Grants
                  </a>
              </li>
              <li className="ft">
                <a href="https:/gdoogle.com" target="_blank" rel="noopener noreferrer" >
                  Leaderboard
                </a>
              </li>
            </ul>
          </div> */}
        </div>

        <div className="pro">
          <div className="main">
              <h4 className="topic">Products</h4>
              <ul className="others">
                <li className="ft" onClick={() => navigate("/mobileapp.html")}>Xend Mobile</li>
                <li className="ft">
                  <a href="https://autoyield.xend.finance/" target="_blank" rel="noopener noreferrer" >
                    Vault
                  </a>
                </li>
                <li className="ft">
                  <a href="https://stake.xend.finance/" target="_blank" rel="noopener noreferrer" >
                    Staking
                  </a>
                </li>
                <li className="ft">
                  <a href="https://www.npmjs.com/package/@xend-finance/web-sdk" target="_blank" rel="noopener noreferrer" >
                    SDK
                  </a>
                </li>
                {/* <li className="ft">
                  <a href="https://launchpad.xend.finance/" target="_blank" rel="noopener noreferrer" >
                    Lauchpad
                  </a>
                </li> */}
                <li className="ft">
                  <HashLink 
                    // className="link-menu"
                    smooth to="/token" 
                    scroll={(el: any) => scrollWithOffset(el)}
                  >
                    <a href="https://xend.finance/token" target="_blank" rel="noopener noreferrer" >
                      RWA Token
                    </a>
                  </HashLink>
                </li>
                <li className="ft">
                  <a href="https://xend.finance/" target="_blank" rel="noopener noreferrer" >
                    X-Wallet
                  </a>
                </li>
                <li className="ft">
                  <a href="https://xendbridge.com/" target="_blank" rel="noopener noreferrer" >
                    XendBridge
                  </a>
                </li>
                <li className="ft">
                  <a href="https://switchwallet.io/" target="_blank" rel="noopener noreferrer" >
                    SwitchWallet
                  </a>
                </li>
                <li className="ft">
                  <a href="https://bridge.xend.finance/" target="_blank" rel="noopener noreferrer" >
                    Native RWA Bridge
                  </a>
                </li>
                <li className="ft">
                  <a href="https://migrate.xend.finance/" target="_blank" rel="noopener noreferrer" >
                    RWA Migration Tool
                  </a>
                </li>
              </ul>
          </div>
        </div>

        <div className="abt">
          <div className="main">
              <h4 className="topic">About</h4>
              <ul className="others">
                <li className="ft">
                  <a href="https://whitepaper.xend.finance/" target="_blank" rel="noopener noreferrer">
                  Whitepaper
                  </a>
                </li>
                <li className="ft" onClick={() => navigate("/team")}>Team</li>
                {/* <li className="ft" onClick={() => navigate("/")}>Media Kits</li> */}
                <li className="ft" onClick={() => navigate("/media")}>Media</li>
                <li className="ft" onClick={() => navigate("/faq")}>FAQs</li>
                <li className="ft" >
                  <a href="https://medium.com/xendfinance" target="_blank" rel="noopener noreferrer">
                    Blog
                  </a>
                </li>
              </ul>
          </div>
        </div>
      </div>
      </div>
      <ul className="foot-down">
        <li className="line-ft" id="copy">&copy; {new Date().getFullYear()} Xend Finance</li>
        <li className="line-ft"><a href="/policy">Privacy Policy</a></li>
        <li className="line-ft"><a href="terms">Terms and Conditions</a></li>
      </ul>
    </footer>
    </>
  )
}



const TelegramDropdown = ({footRef}: any) => {
  
  return (
    <div className='telegram-dropbox' ref={footRef}>
      <h3 className='telegram-title'>Select Telegram Channel</h3>
      <div className='telegram-lines'>
        <a href="https://t.me/XendFinanceDevs" target="_blank" rel="noopener noreferrer" className='telegram-li'>
          Developer Group
        </a>
        <a href="https://t.me/xendFinance" target="_blank" rel="noopener noreferrer" className='telegram-li'>
          Official Group
        </a>
        <a href="https://t.me/XendAnnouncements" target="_blank" rel="noopener noreferrer" className='telegram-li'>
          Announcement Channel
        </a>
        <a href="https://t.me/XendFinanceVietnam" target="_blank" rel="noopener noreferrer" className='telegram-li'>
          Vietnamese
        </a>
        <a href="https://t.me/XendFinanceTurkish" target="_blank" rel="noopener noreferrer" className='telegram-li'>
          Turkish
        </a>
        <a href="https://t.me/XendFinanceRussian" target="_blank" rel="noopener noreferrer" className='telegram-li'>
          Russian
        </a>
        <a href="https://t.me/XendFinanceKorean" target="_blank" rel="noopener noreferrer" className='telegram-li'>
          Korean
        </a>
        <a href="https://t.me/XendFinanceChinese" target="_blank" rel="noopener noreferrer" className='telegram-li'>
          Chinese
        </a>
        <a href="https://t.me/XendFinanceSpanish" target="_blank" rel="noopener noreferrer" className='telegram-li'>
          Spanish
        </a>
        <a href="https://t.me/XendFinanceFrench" target="_blank" rel="noopener noreferrer" className='telegram-li'>
          French
        </a>
        <a href="https://t.me/XendFinancePersian" target="_blank" rel="noopener noreferrer" className='telegram-li'>
          Persian
        </a>
      </div>
    </div>
  )
}

