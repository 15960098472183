import React from 'react'
import {faqData} from "components/data"
import {Fade}  from "react-reveal";

interface Props {
    question?: string;
    answer?: any;
    __html?: any
}

export const Faq = (props: Props) => {
  return (
    <div className="faq">
        <div className="top-1">
            <div className="page-title" id="faq">
                <Fade bottom duration={4000}>
                    <h1 className="topic-1">
                        Frequently Asked Questions (FAQs)
                    </h1>
                </Fade>
                {/* <Fade top duration={4000} >
                    <h4 className="sub-topic-1" >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis
                    </h4>
                </Fade> */}
            </div>
        </div>
        <section className="general-sect">
            <h2 className="general-title">General Questions</h2>
            <div className="faq-box">
            {faqData?.map((faq: any, i: any) => (
                <Question key={i} question={faq?.question} answer={faq?.answer} />
            ))}
            </div>
        </section>
        
        {/* <section className="general-sect">
            <h2 className="general-title">Specific Questions</h2>
            <div className="faq-box">
            {faqData?.map((faq: any, i: any) => (
                <Question key={i} question={faq?.question} answer={faq?.answer} />
            ))}
            </div>
        </section> */}
        
    </div>
  )
}


const Question = ({question, answer}: Props) => {
    const [toggle, setToggle] = React.useState(false)

    return (
        <div className="enquiry" >
            <div className={`question-box ${toggle && "quest-active"}`} onClick={() => setToggle(!toggle)}>
                <p id="question">{question}</p>
                <img src={toggle ? "/icons/arrow-up.svg" : "/icons/arrow-down.svg"} alt="toggle" id="arrow"/>
            </div>
            {toggle && (
                <p dangerouslySetInnerHTML={{ __html: answer }} id={toggle ? "show-answer" : "hide-answer"}></p>
            )}
        </div>
    )
}