import axios from "axios";
import { TokenStatisticsLoadingState } from "components/TokenStatisticsLoadingState";
import { useEffect, useState } from "react";
import { Zoom, Fade } from "react-reveal";
import { _copyToClipboard } from "utils";
import { arbitrumAddress, bscAddress, ethereumAddress, huobiAddress, polygonAddress } from "utils/common";
import { numberWithCommas } from "utils/numberWithComma";
import { splitAddress } from "utils/splitWalletAddress";

type Props = {}

export const Token = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [xendData, setXendData] = useState({
        market_cap: '11,393,539.70',
        circulating_supply: '128,976,418.44',
        total_supply: '200,000,000.00',
        price: '0.08575',
        trading_volume: '56,515.4012',
        percent_change_24h: '0.72895',
        last_updated: '15:40'
    });

    const getXendData = async() => {
        setLoading(true);
        try {
            const res = await axios.get(`https://api.coincap.io/v2/assets/xend-finance`);
            if(res.status === 200) {
                let responseData = res.data.data;
                let dateObj = new Date(res.data.timestamp);
                let hour = dateObj.getUTCHours();
                let minutes = dateObj.getUTCMinutes();
                setXendData({
                    market_cap : numberWithCommas(responseData.marketCapUsd),
                    circulating_supply: numberWithCommas(responseData.supply),
                    total_supply: numberWithCommas(responseData.maxSupply),
                    price: numberWithCommas(responseData.priceUsd),
                    trading_volume: numberWithCommas(responseData.volumeUsd24Hr),
                    percent_change_24h: numberWithCommas(responseData.changePercent24Hr),
                    last_updated: `${hour}:${minutes}`
                });
            }
        } catch(error) {
            console.log({error});
        }
        setLoading(false);
    }

    useEffect(() => {
        getXendData()
        .catch(console.error);
    }, []);

    return (
        <div className="token">
            <div className="top-1">
                <Fade bottom duration={4000}>
                    <div className="page-title" id="token">
                        <h1 className="topic-1">
                            RWA Token
                        </h1>
                        <h4 className="sub-topic-1" >
                            $RWA Token <br />
                            The Xend token ($RWA) powers a decentralized savings and cross-chain high yield aggregator protocol.
                            The $RWA tokens also generate interest for the users as they hold on to the tokens.
                        </h4>
                    </div>
                </Fade>
            </div>

            {/* <div className="cards-sect">
                <Zoom duration={4000} >
                    <div className="card">
                        <img src="/icons/automated.svg" alt="" className="card-icon" />
                        <h4 className="card-title">Automated Yield Aggregator</h4>
                        <p className="card-content">
                            The Xend Finance lending protocol aggregator – unlike other DeFi protocols who only list other Layer-1 DeFi protocols – will automatically shift funds across different lending protocols to provide very high yields without any input from the user.
                        </p>
                    </div>
                </Zoom>

                <div className="card">
                    <img src="/icons/x-vault.svg" alt="" className="card-icon" />
                    <h4 className="card-title">x-Vault system</h4>
                    <p className="card-content">
                        The x-vault system is a system that implements multiple yield generation strategies that automatically seek out the highest yields available in DeFi. These x-vaults accept your deposit, then the vault system distributes the funds through multiple strategies, which automatically seek out the best yields (inspired by yearn vaults). Supported Blockchains: BSC and Polygon.
                    </p>
                </div>

                <Zoom duration={4000}>
                    <div className="card">
                        <img src="/icons/governance.svg" alt="" className="card-icon" />
                        <h4 className="card-title">Governance</h4>
                        <p className="card-content">
                            The Xend Finance governance system will determine strategies that are accepted into the vaults and community members who participate in governance earn a part of the processing fees from the vault operations. The Xend Finance governance system will be deployed across blockchains and users can govern on multiple blockchains.
                        </p>
                    </div>
                </Zoom>
            </div> */}

            {/* <div className="statistics-section">
                <h3 className="token-statistics"> $RWA Token Price Statistics</h3>
                {
                    loading ? <TokenStatisticsLoadingState />
                    : (
                    <div className="statistics-container">
                        <div className="token-statistics-box">
                            <div className="mini-card">
                                <p className="mini-card-label">RWA Token Price</p>
                                <span className="mini-card-value">
                                    ${xendData?.price}
                                </span>
                            </div> 
                            <div className="mini-card">
                                <p className="mini-card-label">24 Hour Trading Volume</p>
                                <span className="mini-card-value">
                                    ${xendData?.trading_volume}
                                </span>
                            </div>
                            <div className="mini-card">
                                <p className="mini-card-label">24 Hour Change %</p>
                                <span className="mini-card-value">
                                    {xendData?.percent_change_24h} %
                                </span>
                            </div>

                            <div className="mini-card">
                                <p className="mini-card-label">Market Cap</p>
                                <span className="mini-card-value">
                                    ${xendData?.market_cap}
                                </span>
                            </div>
                            <div className="mini-card">
                                <p className="mini-card-label">Circulating Supply</p>
                                <span className="mini-card-value">
                                    {xendData?.circulating_supply} XEND 
                                </span>
                            </div>
                            <div className="mini-card">
                                <p className="mini-card-label">Total Volume</p>
                                <span className="mini-card-value">
                                    ${xendData?.total_supply}
                                </span>
                            </div>
                        </div>
                        <div className="statistics-info-box">
                            <span className="statistics-info">
                                All information is fetched from Coincap
                            </span>
                            <span className="statistics-info">
                                Last Updated at {xendData?.last_updated} GMT+1
                            </span>
                        </div>
                    </div>
                )}
            </div> */}

            <div className="token-providers-section">
                <p className="providers-title">
                    Xend Finance Tokens have been issued on below public chains
                </p>
            
                <div className="providers-box">
                    <div className="provider-card">
                        <img src="/icons/binance-smart-chain.svg" alt="binance" className="provider-image" />
                        <div className="provider-info">
                            <p>Binance Smart Chain</p>
                            <span className="provider-address">{splitAddress(bscAddress)}</span>
                        </div>
                        <img src="/icons/Copy-white.svg" alt="copy" className="copy" 
                            onClick={() => _copyToClipboard(bscAddress, "Bsc Address Copied")}
                        />
                    </div>  

                    <div className="provider-card">
                        <img src="/icons/polygon-eco-chain.svg" alt="polygon" className="provider-image" />
                        <div className="provider-info">
                            <p>Polygon Eco Chain</p>
                            <span className="provider-address">{splitAddress(polygonAddress)}</span>
                        </div>
                        <img src="/icons/Copy-white.svg" alt="copy" className="copy" 
                            onClick={() => _copyToClipboard(polygonAddress, "Polygon Address Copied")}
                        />
                    </div>   
                </div>  

                <div className="providers-box">
                    <div className="provider-card">
                        <img src="/icons/Ethereum-Icon.svg" alt="ethereum" className="provider-image" />
                        <div className="provider-info">
                            <p>Ethereum</p>
                            <span className="provider-address">{splitAddress(ethereumAddress)}</span>
                        </div>
                        <img src="/icons/Copy-white.svg" alt="copy" className="copy" 
                            onClick={() => _copyToClipboard(ethereumAddress, "Ethereum Address Copied")}
                        />
                    </div>  

                    <div className="provider-card">
                        <img src="/icons/arbitrum-white.svg" alt="huobi" className="provider-image" />
                        <div className="provider-info">
                            <p>Arbitrum</p>
                            <span className="provider-address">{splitAddress(arbitrumAddress)}</span>
                        </div>
                        <img src="/icons/Copy-white.svg" alt="copy" className="copy" 
                            onClick={() => _copyToClipboard(arbitrumAddress, "Arbitrum Address Copied")}
                        />
                    </div>   
                </div>  
            </div>

            <h3 className="get">Get $RWA Here</h3>
            <p className="xchange">Exchanges that offer $RWA Tokens</p>
            <div className="xchanges-box">
                {/* <a href="https://www.gate.io/trade/XEND_USDT/?ch=en_sm_0321e" target="_blank" rel="noopener noreferrer" className="x-logo">
                    <img src="/icons/gate-io.svg" alt=""  />
                </a> */}
                {/* <a href="//https://ascendex.com/en/basic/cashtrade-spottrading/usdt/xend" target="_blank" rel="noopener noreferrer" className="x-logo">
                    <img src="/icons/ascendex.svg" alt=""  />
                </a> */}
                <a href="https://app.uniswap.org/tokens/arbitrum/0x3096e7bfd0878cc65be71f8899bc4cfb57187ba3" target="_blank" rel="noopener noreferrer" className="x-logo">
                    <img src="/icons/uniswap.svg" alt="" />
                </a>
                <a href="https://www.dextools.io/app/en/arbitrum/pair-explorer/0x066b28f0c160935cf285f75ed600967bf8417035" target="_blank" rel="noopener noreferrer" className="x-logo">
                    <img src="/icons/dextools.svg" alt="" />
                </a>
                {/* <a href="https://exchange.pancakeswap.finance/#/swap" target="_blank" rel="noopener noreferrer" className="x-logo">
                    <img src="/icons/pancakeswap.svg" alt="" />
                </a> */}
            </div>

            <h3 className="token-statistics">$RWA Token Price Statistics</h3>
            <div className="statistics-section">
                <div className="coinmarketcap-currency-widget" 
                    data-currencyid="8519" data-base="USD" data-secondary="" 
                    data-ticker="true" data-rank="false" 
                    data-marketcap="false" data-volume="false" 
                    data-statsticker="false" data-stats="USD"
                ></div>
            </div>

            {/* <Fade bottom duration={4000}>
                <h2 className="distribtion-model-header">$RWA Distribution Model</h2>
                <img src="/icons/rwa-distribution-model.svg" alt="strategic round" className="distribution-chart-image" />    
            </Fade> */}
        </div>
    );
}