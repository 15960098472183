import { useState} from 'react'
import { Fade } from "react-reveal";

type Props = {}

export const NewRoadmap = () => {
    const [dropdown, setDropdown] = useState({open: false, id: ""});
    const openDropdown = (id: any) => {
        setDropdown((prevDropdown) => ({
            open: prevDropdown.id === id ? !prevDropdown.open : true,
            id, 
        }));
    };


  return (
    <div className="new-roadmap">
        <Fade right duration={1000}>
            <h2 className='stages-header'>
                Discover our technology<br /> and the vision of Xend <br /> Finance(RWA).
            </h2>
        </Fade>
                    
        <div className='subsection-container subsection-1' id='subsection-1'>
            {/* <img src="/icons/growth-circle.svg" alt="" id="circle-icon" />
            <div className='divider-line' /> */}
            <div className='stages-title-selector-box'>
                <p className='active-stage'>Stage 1</p>
            </div>
            <h3>RWA blockchain, Origin studio and Gor register</h3>
            <Fade right duration={1000}>
                <div className='stages-subsection-title-box' onClick={()=>openDropdown("stage-1-rwa-line")}>
                    <h4 className='subsection-title'>Products - RWA line</h4>
                    <div className='subsection-progress-indicator-box'>
                        { dropdown.id === 'stage-1-rwa-line' && dropdown.open ? 'Collapse' : 'Expand' }
                    </div>
                </div>
            </Fade>

            {dropdown.open && dropdown.id === "stage-1-rwa-line" && (<div className={``}>   
                <Fade cascade damping={10.9}>
                    <div className='subsection-box'>
                        <h5 className='subsection-heading'>RWA blockchain</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Token standards linked with legally recognized asset types</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Open source connectivity environment to connect RWA blockchain with other blockchain and external data</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Off-ramp and on-ramp solutions for asset conversion and onboarding</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Wallets serve as digital ID and representation of physical asset owners</span>
                            </li>
                        </ul>
                    </div>
                    <div className='subsection-box'>
                        <h5 className='subsection-heading'>Origin studio</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">A platform designed for tokenizing Real-World Assets (RWA) and integrating them into the RWA blockchain.</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Facilitates the legalization of existing tokens by registering them on the RWA blockchain and associating them with defined asset types, such as equities and shares</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Framework for the authentication and insurance of assets</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Supports programmable legal events, including ownership transfers, divisions, restrictions, inheritance, and use as collateral</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Sandbox environment for testing the tokenization and operation of assets in a demo setting without triggering legal consequences.</span>
                            </li>
                        </ul>
                    </div>
                    <div className='subsection-box'>
                        <h5 className='subsection-heading'>GOR register</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Explorer for tokenized asset profiles and RWA blockchain transactions.</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Features an interface specifically for browsing tokenized asset profiles</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Includes a separate explorer for transactions involving Real-World Assets (RWA)</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Provides an asset lifecycle tracker to monitor the stages of an asset's existence</span>
                            </li>
                        </ul>
                    </div>
                    <div className='subsection-box' id='stage-1-subsection-1-end'>
                        <h5 className='subsection-heading'>Xend blockchain wallet</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Allows to store assets and currencies</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Connected with RWA and Xend Solutions product lines </span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Allow to create multi-signature policies</span>
                            </li>
                        </ul>
                    </div>
                </Fade>
            </div>)}

            {/*  Products - Xend Solutions Line start*/}
            <Fade right duration={1000}>
                <div className='stages-subsection-title-box' onClick={()=>openDropdown("stage-1-xend-solutions-line")}>
                    <h4 className='subsection-title'>Products - Xend Solutions Line</h4>
                    <div className='subsection-progress-indicator-box'>
                        { dropdown.id === 'stage-1-xend-solutions-line' && dropdown.open ? 'Collapse' : 'Expand' }
                    </div>
                </div>
            </Fade>
            {dropdown.open && dropdown.id === 'stage-1-xend-solutions-line' && (<div className=''>
                <Fade cascade damping={10.9}>
                    <div className='subsection-box'>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Xend Bank</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Xend CeDeFi Exchange</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Decentralized P2P credit platform</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Fundraising and incubation platform</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Xend Connect (Centralized & Decentralized Data Oracles Hub)</span>
                            </li>
                        </ul>
                    </div>
                </Fade>
            </div>)}
            {/*  Products - Xend Solutions Line end*/}

            {/*  Organizational framework start*/}
            <Fade right duration={1000}>
                <div className='stages-subsection-title-box' onClick={()=>openDropdown("stage-1-org-framework")}>
                    <h4 className='subsection-title'>Organizational framework</h4>
                    <div className='subsection-progress-indicator-box'>
                        { dropdown.id === 'stage-1-org-framework' && dropdown.open ? 'Collapse' : 'Expand' }
                    </div>
                </div>
            </Fade>
            {dropdown.open && dropdown.id === 'stage-1-org-framework' && (<div className=''>
                <Fade cascade damping={10.9}>
                    <div className='subsection-box'>
                        <h5 className='subsection-heading'>IAC partnerships </h5>
                        <ul className='subsection-plan-list' id="last-subsection">
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Establishing organizational frameworks for Authentication, Insurance, and Compliance Validity of asset</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Engaging directly with companies offering these services.</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">
                                    Expanding the global territorial coverage of partners to enable tokenization through the IAC triangle <br/>
                                    for every tangible and intangible asset under all possible legislative frameworks.
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className='subsection-box'>
                        <h5 className='subsection-heading'>RWA Stake-to Mode</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Introducing a profit distribution framework based on RWA for IAC partners.</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Connecting RWA with Xend solutions through incentivized and gamified interactions.</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">
                                    Finalizing the RWA blockchain Proof of Stake (PoS) model and rewards for nodes
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className='subsection-box'>
                        <h5 className='subsection-heading'>Asset legalization campaign</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Organizing a large-scale event aimed at legalizing existing tokens through the RWA chain.</span>
                            </li>
                        </ul>
                    </div>
                </Fade>
            </div>)}
      
            {/* STAGE 2 */}
            <div className='stages-title-selector-box'>
                <p className='active-stage'>Stage 2</p>
            </div>
            <h3>World Wide Blockchain</h3>
            <Fade right duration={1000}>
                <div className='stages-subsection-title-box' onClick={()=>openDropdown("stage-2-rwa-line")}>
                    <h4 className='subsection-title'>Products - RWA line</h4>
                    <div className='subsection-progress-indicator-box'>
                        { dropdown.id === 'stage-2-rwa-line' && dropdown.open ? 'Collapse' : 'Expand' }
                    </div>
                </div>
            </Fade>
            {/* STAGE 2 RWA INE START */}
            {dropdown.open && dropdown.id === 'stage-2-rwa-line' && (<div className=''>
                <Fade cascade damping={10.9}>
                    <div className='subsection-box'>
                        <h5 className='subsection-heading'>Local RWA Subchains</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Creating RWA subchains that directly correspond with the legal frameworks of specific countries concerning assets and ownership</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Appointing country-specific IAC councils responsible for maintaining and developing these subchains</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Enabling legal causality, where events on country-specific DDPA blockchains <br/> are recognized as legal events within that country's legal framework</span>
                            </li>
                        </ul>
                    </div>

                    <div className='subsection-box'>
                        <h5 className='subsection-heading'>Xend Browser</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">A dedicated web browsing solution akin to Google Chrome or Mozilla Firefox</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">RWA Explorer: A tool for exploring sub-chains, asset profiles, and transactions</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">RWA Domain Register: Enables the setup of asset domains as websites,<br /> utilizing the RWA blockchain as an equivalent to DNS for an independent internet.</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">RWA Subnet creator: Enables creating own independent internet at the country, regional, or local level</span>
                            </li>
                        </ul>
                    </div>

                    <div className='subsection-box'>
                        <h5 className='subsection-heading'>NodeOs </h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Integrated with the Xend Browser, designed to operate seamlessly in the background.</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Enables the transformation of any device, whether mobile or stationary, with an internet connection and hard drive,<br /> 
                                into a MicroNode. This contributes to the creation and maintenance of the RWA blockchain and supports RWA decentralized oracles.</span>
                            </li>
                        </ul>
                    </div>

                    <div className='subsection-box' id='stage-1-subsection-1-end'>
                        <h5 className='subsection-heading'>NodeID</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">A specialized solution that connects NodeOs with a legally valid digital ID,<br /> 
                                ensuring secure and authenticated participation in the network</span>
                            </li>
                        </ul>
                    </div>

                    <div className='subsection-box' id='stage-1-subsection-1-end'>
                        <h5 className='subsection-heading'>NodePay</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">A dedicated payment solution for devices running NodeOS, facilitating transactions between two devices</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Supports both physical and digital payments, broadening the scope of transactions within the NodeOS ecosystem.</span>
                            </li> 
                        </ul>
                    </div>
                    <div className='subsection-box' id='stage-1-subsection-1-end'>
                        <h5 className='subsection-heading'>Xend HybridWallet </h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Function as both traditional bank account and blockchain wallet at once</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Allows for auto conversion between fiat currencies and stablecoins to streamline transactions and asset management.</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Includes a built-in foreign exchange feature within the wallet, simplifying currency exchanges directly within the platform.</span>
                            </li>
                        </ul>
                    </div>
                </Fade>
            </div>)}
            {/* STAGE 2 RWA INE END */}

            {/* STAGE 2 XEND SOLUTIONS LINE START */}
            <Fade right duration={1000}>
                <div className='stages-subsection-title-box' onClick={()=>openDropdown("stage-2-xend-solutions-line")}>
                    <h4 className='subsection-title'>Products - Xend Solutions Line</h4>
                    <div className='subsection-progress-indicator-box'>
                        { dropdown.id === 'stage-2-xend-solutions-line' && dropdown.open ? 'Collapse' : 'Expand' }
                    </div>
                </div>
            </Fade>
            {dropdown.open && dropdown.id === 'stage-2-xend-solutions-line' && (<div className=''>
                <Fade cascade damping={10.9}>
                    <div className="subsection-box" id='stage-1-subsection-2-start'>
                        <h5 className='subsection-heading'>Xend Mailbox</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Enables the sending and receiving of secure on-chain emails, allowing users to confirm delivery through transaction links.</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Utilizes the RWA wallet as a mailbox, integrating communication directly with asset management</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Provides control over who can access and read emails by managing the hashing and de-hashing keys, ensuring privacy and security</span>
                            </li>
                        </ul>
                    </div>

                    <div className="subsection-box" id='stage-1-subsection-2-start'>
                        <h5 className='subsection-heading'>RWA dApp Marketplace </h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Specialized platform hosting a variety of plugins and software solutions</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">All solutions are compatible with RWA and Xend product lines, as well as the RWA blockchain </span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Crafted to streamline and automate local processes, regulatory compliance, and legal obligations through the use of digital tokens.</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Designed to simplify and globalize the automation of tax, accounting, and auditing tasks.</span>
                            </li>
                        </ul>
                    </div>
                </Fade>
            </div>)}
            {/* STAGE 2 XEND SOLUTIONS LINE START END */}

            {/*  Organizational framework start*/}
            <Fade right duration={1000}>
                <div className='stages-subsection-title-box' onClick={()=>openDropdown("stage-2-org-framework")}>
                    <h4 className='subsection-title'>Organizational framework</h4>
                    <div className='subsection-progress-indicator-box'>
                        { dropdown.id === 'stage-2-org-framework' && dropdown.open ? 'Collapse' : 'Expand' }
                    </div>
                </div>
            </Fade>
            {dropdown.open && dropdown.id === 'stage-2-org-framework' && (<div className=''>
                <Fade cascade damping={10.9}>
                    <div className='subsection-box'>
                        <h5 className='subsection-heading'>IAC Localization</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Implementation of the RWA stake-to model to allocate exclusive rights for managing country-specific compliance, <br />
                                insurance, and authentication processes within the RWA ecosystem.</span>
                            </li>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Progressive formation of 195 IAC councils coordinated by Xend Finance</span>
                            </li>
                        </ul>
                    </div>

                    <div className='subsection-box'>
                        <h5 className='subsection-heading'>Progressive regulatory synch</h5>
                        <ul className='subsection-plan-list'>
                            <li className='subsection-plan'>
                                <img src="/icons/dot.svg" alt="" id="status"/>
                                <span className="detail">Establishing agreements with local regulatory bodies to ensure a direct seamless, <br/>
                                two-way synchronization between a country's legal events and the corresponding events on RWA sub-chains <br />
                                (i.e. RWA subchains with the highest legal causality level)</span>
                            </li>
                        </ul>
                    </div>
                </Fade>
            </div>)}
            {/*  Organizational framework end*/}
        </div>
                   
    </div>
  )
}