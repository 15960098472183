import { Fade } from "react-reveal";
import { useEffect, useState } from "react";
import moment from "moment";

export const TermsAndConditions = () => {
    const [updatedTime, setUpdatedTime] = useState<string>('')
    useEffect(() => {
        setUpdatedTime(moment("20220928", "YYYYMMDD").fromNow());
    },[]);
  
    return (
        <div className="terms-conditions">
            <div className="top-1">
                <Fade bottom duration={4000}>
                    <div className="page-title" id="token">
                        <h1 className="topic-1 term-h1">
                            Terms and Conditions
                        </h1>
                    </div>
                </Fade>
            </div>

            <section className="policy-section">
                <span className="terms-note">
                    PLEASE NOTE THAT YOUR USE OF AND ACCESS TO THE SERVICES (AS DEFINED BELOW) ARE SUBJECT TO THE FOLLOWING TERMS; 
                    IF YOU DO NOT AGREE TO ALL OF THESE TERMS, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
                </span>
                
                <span className="update-policy-time">Last updated: {updatedTime}</span>
                
                <p className="terms-intro">
                    These Terms of Use (these "Terms") represent an agreement between you and Xend Labs Limited (hereinafter referred to as Xend Finance), a private limited liability company incorporated in the British Virgin Islands. 
                    By signing up to use our services through our mobile applications, you agree that you have read, understood, and accepted all of the terms and conditions contained in this Agreement, as well as our Privacy Policy.<br/>
                </p>
                <p className="terms-intro">
                    This Agreement is legally binding and serves to govern your use of this site. 
                    Please read its terms carefully before registering your account because they affect your legal rights and obligations. 
                    If you do not agree with any of the terms contained herein, kindly refrain from using this service.<br/>
                </p>
                <p className="terms-intro">
                    Xend Finance reserves the right to update, revise, modify, or otherwise, reissue these terms without prior notice. 
                    The continued use of your Xend Finance account after any amendment to this Agreement constitutes your acceptance of the Agreement as modified by such amendment.<br/>
                </p>
                <div className="terms-box">
                    <span className="policy">1. Eligibility</span>
                    <p>
                        To use our application and services you must be at least 18 years of age, with full legal capacity to accept this Agreement and enter into any transaction on the app. 
                        If as a parent or guardian, you become aware that your child or ward child has provided us with any information without your consent, please contact us through details on the Privacy Policy.
                    </p>
                </div>

                <div className="terms-box">
                    <span className="policy">2. Risks</span>
                    <p>
                        Before using the Xend Finance application, you should ensure that that you understand the risks involved in buying, selling or trading Digital Assets as such markets are known to be volatile and prices can fluctuate significantly, which could result in sudden and significant increases or decreases in the value of your assets.
                    </p>

                    <p>
                        You should carefully assess whether your financial situation and risk tolerance is suitable for buying, selling or trading Digital Assets. You accept and agree that you are solely responsible for any decision to buy, sell, trade or otherwise hold or deal with cryptocurrency.
                    </p>

                    <p>
                        Under no circumstances does any information contained on the Xend Finance App, or provided to you through your Xend Finance Account or by any employee, agent or affiliate of Xend Finance, constitute financial, investment or other professional advice. 
                        You are solely responsible for any decision to store, buy or sell cryptocurrency, and such decisions should take into account your risk tolerance and financial circumstances.
                    </p>
                </div>

                <div className="terms-box">
                    <span className="policy">3. About Xend Finance</span>
                    <p>
                        Xend Finance allows holders of a Xend Finance Account to access the following basic services (“Xend Finance Services”) via the application:
                    </p>

                    <p>
                        a. Buy, sell and save the supported Digital Assets in transactions with other Xend Finance users (“Exchange Service”).
                    </p>

                    <p>
                        b. A wallet to store Digital Currencies (“Xend Finance Wallet”) for use in connection with the Xend Finance Services.
                    </p>
                    <p>
                        c. Deposit and withdraw Digital Currencies.
                    </p>
                </div>

                <div className="terms-box">
                    <span className="policy">4. Using the App</span>
                    <p>
                        To use the application, you are required to open a Xend Finance Account and affirm your acceptance of this Agreement. Xend Finance may, at our sole discretion, revoke account registrations, suspend, or limit access to your Xend Finance Account and/or the Xend Finance Services and/or limit the number of Xend Finance Accounts a single user may establish and maintain at any time.
                    </p>

                    <p>
                        You may be required to provide information to identify yourself including your full name, phone number, e-mail address, date of birth, and information regarding your bank account (e.g., financial institution and account number).
                    </p>

                    <p>
                        By submitting these or any other personal information as may be required, you confirm that the information provided is accurate and authentic, and you agree to update Xend Finance if any information changes and further consent to Xend Finance to directly or through third parties, make any inquiries we consider necessary to verify your identity and/or protect against fraud. 
                        You further authorize any and all third parties to which such inquiries or requests may be directed to fully respond to such inquiries or requests.
                    </p>
                </div>

                <div className="terms-box">
                    <span className="policy">5. Xend Finance Wallet</span>
                    <p>
                        The Xend Finance Wallet services allow you to send the supported fiat currency and/or Digital Assets to, and request, receive, and store supported Digital Asset from, third parties pursuant to instructions you provide through the Xend Finance App. ("Digital Asset Transaction"). Eligible users in certain jurisdictions may buy or sell supported Digital Asset directly from Xend Finance. Xend Finance reserves the right to limit the amount of Digital Assets available for sale.
                    </p>

                    <p>
                        After successfully completing the verification procedures, you may buy supported Digital Assets directly from Xend Finance. A payment processing fee applies to all purchase transactions. Xend Finance uses third-party payment processors to process any Fiat Currency payment between you and Xend Finance.
                        Xend Finance reserves the right to refuse to process or to cancel any pending fiat currency transaction and/or Digital Asset Transaction as required by law or in response to a subpoena, court order, or other binding government order or to enforce transaction limits. 
                        Xend Finance cannot reverse a Digital Asset Transaction that has been broadcast to the Digital Asset’s network.
                    </p>

                    <p>
                        The Xend Finance Wallet is available only in connection with those fiat currencies and/or Digital Assets that Xend Finance, in its sole discretion, decides to support. Under no circumstances should you attempt to use your Xend Finance Wallet services to store, send, request, or receive currency or Digital Assets in any form that is not supported by Xend Finance. 
                        Xend Finance assumes no responsibility or liability in connection with any attempt to use Xend Finance Services for a currency that Xend Finance does not support.
                    </p>

                    <p>
                       Xend Finance processes all transactions according to the instructions received from its users and we do not guarantee the identity of any user, receiver, requester or other party. You should verify all transaction information prior to submitting instructions to Xend Finance. In the event you initiate a Digital Asset Transaction by entering the recipient's Digital Asset address on the network, a network fee will be incurred. Once submitted to a Digital Asset network, a Digital Asset Transaction will be unconfirmed for a period of time pending sufficient confirmation of the transaction by the Digital Asset network. A transaction is not complete while it is in a pending state. Funds associated with transactions that are in a pending state will be designated accordingly, and will not be included in your Xend Finance Account’s available balance or be available to conduct transactions. Xend Finance may charge network fees (gas fees) to process a Digital Asset transaction on your behalf. 
                       Xend Finance will calculate the network fee at its discretion, although Xend Finance will always notify you of the network fee at or before the time you authorize the transaction.
                    </p>

                    <p>
                        Xend Finance securely stores all Digital Asset private keys in our control in a combination of online and offline storage. 
                        As a result, it may be necessary for Xend Finance to retrieve certain information from offline storage in order to facilitate a Digital Asset Transaction in accordance with your instructions, which may delay the initiation or credit of such Digital Asset Transaction for 48 hours or more. 
                        You acknowledge and agree that a Digital Asset Transaction facilitated by Xend Finance may be delayed.
                    </p>

                    <p>
                        Xend Finance has no control over, or liability for, the delivery, quality, safety, legality or any other aspect of any goods or services (excluding Digital Assets bought or sold via the Xend Finance App) that you may purchase or sell to or from a third party (including other users of Xend Finance Services). Xend Finance does not own or control the underlying software protocols which govern the operation of Digital Currencies supported on our platform. In general, the underlying protocols are open source and anyone can use, copy, modify, and distribute them. 
                        By using the Xend Finance platform, you acknowledge and agree to the following:                    
                    </p>

                    <p>
                        Xend Finance is not responsible for the operation of the underlying protocols and that Xend Finance makes no guarantee of their functionality, security, or availability; and
                        The underlying protocols are subject to sudden changes in operating rules (a.k.a “forks”), and that such forks may materially affect the value, function, and/or even the name of the Digital Asset you store on the Xend Finance platform. In the event of a fork, you agree that Xend Finance may temporarily suspend Xend Finance operations (with or without advance notice to you) and that Xend Finance may, in its sole discretion, decide whether or not to support (or cease supporting) either branch of the forked protocol entirely. 
                        You acknowledge and agree that Xend Finance assumes absolutely no responsibility whatsoever in respect of an unsupported branch of a forked protocol.
                    </p>

                    <p>
                        Fiat currency deposited into your Xend Finance Wallet is solely for (1) the purchase of supported Digital Assets, (2) Transfer from one Xend Finance Wallet to another and/or (2) Withdrawal to an account. In certain countries, we may also allow you to purchase specific goods and/or services from selected third parties using your balance. You accept and agree that any such purchase will be subject to these Terms. Proceeds from the sale of supported Digital Assets will be credited to the Xend Finance Wallet, less any applicable fees. 
                        Xend Finance does not pay interest on fiat currency or Digital Asset balances held in your Xend Finance Wallet
                    </p>

                    <p>
                        You cannot cancel, reverse, or change any transaction marked as complete or pending. If your payment is not successful or if your payment method has insufficient funds, you authorize Xend Finance, in its sole discretion to cancel the transaction. 
                        You are responsible for maintaining an adequate balance and/or sufficient credit limits in order to avoid overdraft, insufficient funds, or similar fees charged by your payment provider (i.e. your bank).
                        Xend Finance reserves the right to reverse any transaction that was made on the company’s platform using invalid prices. For the purposes of this clause, an Invalid Price shall be interpreted as any price that results in a 2.5% difference between the price displayed on the Platform and the prevailing market prices of the Cryptocurrency asset in question the date and time of the transaction.
                    </p>
                </div>

                <div className="terms-box">
                    <span className="policy">6. General Use, Prohibited Use and Termination</span>

                    <p>
                        We grant you a limited, nonexclusive, non-transferable license, subject to the terms of this Agreement, to access and use the Xend Finance App, and related content, materials, information (collectively, the "Content") solely for approved purposes as permitted by Xend Finance from time to time. 
                        Any other use of the Xend Finance App or Content is expressly prohibited and all other right, title, and interest in the Xend Finance App or Content is exclusively the property of Xend Finance and its licensors. 
                        You agree you will not copy, transmit, distribute, sell, license, reverse engineer, modify, publish, or participate in the transfer or sale of, create derivative works from, or in any other way exploit any of the Content, in whole or in part. 
                        " Xend Finance " and all logos related to Xend Finance Services or displayed on the Xend Finance App are either trademarks or registered marks of Xend Finance or its licensors. 
                        You may not copy, imitate or use them without Xend Finance's prior written consent.
                    </p>

                    <p>
                        Although we intend to provide accurate and timely information on the Xend Finance App, the Xend Finance App (including, without limitation, the Content) may not always be entirely accurate, complete or current and may also include technical inaccuracies or typographical errors. 
                        In an effort to continue to provide you with as complete and accurate information as possible, information may be changed or updated from time to time without notice, including without limitation information regarding our policies, products and services. Accordingly, you should verify all information before relying on it, and all decisions based on information contained on the Xend Finance App are your sole responsibility and we shall have no liability for such decisions. 
                        Links to third-party materials may be provided as a convenience but are not controlled by us. 
                        You acknowledge and agree that we are not responsible for any aspect of the information, content, or services contained in any third-party materials or on any third party sites accessible or linked to the Xend Finance App.
                    </p>

                    <p>
                        If, to the extent permitted by Xend Finance from time to time, you grant express permission to a third party to access or connect to your Xend Finance Account, either through the third party's product or service or through the Xend Finance App, you acknowledge that granting permission to a third party to take specific actions on your behalf does not relieve you of any of your responsibilities under this Agreement. 
                        You are fully responsible for all acts or omissions of any third party with access to your Xend Finance Account. Further, you acknowledge and agree that you will not hold Xend Finance responsible for, and will indemnify Xend Finance from, any liability arising out of or related to any act or omission of any third party with access to your Xend Finance Account.
                    </p>

                    <p>
                        In connection with your use of the Xend Finance Services, and your interactions with other users, and third parties you agree and represent you will not engage in any Prohibited Business or Prohibited Use defined herein. 
                        We reserve the right at all times to monitor, review, retain and/or disclose any information as necessary to satisfy any applicable law, regulation, sanctions programs, legal process or governmental request. 
                        We reserve the right to cancel and/or suspend your Xend Finance Account and/or block transactions or freeze funds immediately and without notice if we determine, in our sole discretion, that your Account is associated with a Prohibited Use and/or a Prohibited Business.
                    </p>

                    <p>
                        Your use of the Xend Finance Services and the Xend Finance App is subject to international export controls and economic sanctions requirements. 
                        By sending, receiving, buying, selling, trading or storing Digital Asset through the Xend Finance App or Xend Finance Services, you represent and warrant that you will conform with those requirements.
                    </p>

                    <p>
                        The use of all Xend Finance Services is subject to a limit on the amount of volume, stated in fiat currency terms, you may transact or transfer in a given period (e.g., daily). 
                        Your transaction limits may vary depending on your payment method, financial institution and other factors. 
                        To find out your limits, please contact your financial institution.
                    </p>

                    <p>
                        Xend Finance may: (a) suspend, restrict, or terminate your access to any or all of the Xend Finance Services, and/or (b) deactivate or cancel your Xend Finance Account if:
                        We are so required by a facially valid subpoena, court order, or binding order of a government authority; or We reasonably suspect you of using your Xend Finance Account in connection with a Prohibited Use or Business; or Use of your Xend Finance Account is subject to any pending litigation, investigation, or government proceeding and/or we perceive a heightened risk of legal or regulatory non-compliance associated with your Account activity; or Our service partners are unable to support your use; or
                        You take any action that Xend Finance deems as circumventing Xend Finance 's controls, including, but not limited to, opening multiple Xend Finance Accounts or abusing promotions which Xend Finance may offer from time to time.
                    </p>

                    <p>
                        If Xend Finance suspends or closes your account, or terminates your use of Xend Finance Services for any reason, we will provide you with notice of our actions unless a court order or other legal process prohibits Xend Finance from providing you with such notice. 
                        You acknowledge that Xend Finance 's decision to take certain actions, including limiting access to, suspending, or closing your account, may be based on confidential criteria that are essential to Xend Finance 's risk management and security protocols. You agree that Xend Finance is under no obligation to disclose the details of its risk management and security procedures to you.
                    </p>

                    <p>
                        You will be permitted to transfer Digital Asset or funds associated with your Xend Finance Wallet for ninety (90) days after Account deactivation or cancellation unless such transfer is otherwise prohibited (i) under the law, including but not limited to applicable sanctions programs, or (ii) by a facially valid subpoena or court order. 
                        If Xend Finance suspends or closes your account, or terminates your use of Xend Finance Services for any reason Xend Finance reserves the right to require you to complete the Verification Procedures before permitting you with transfer or withdraw Digital Asset or fiat Currency.
                    </p>

                    <p>
                        Right now, you cannot cancel or deactivate your Xend Finance Account. 
                        In the event whereby you wish to, please contact us at support@xend.africa. 
                        If you’re sending a cancellation request, you authorize us to cancel or suspend any pending transactions at the time of cancellation.
                    </p>

                    <p>
                        Xend Finance is an independent contractor for all purposes. 
                        Nothing in this Agreement shall be deemed or is intended to be deemed, nor shall it cause, you and Xend Finance to be treated as partners, joint ventures, or otherwise as joint associates for profit, or either you or Xend Finance to be treated as the agent of the other.
                    </p>

                    <p>
                        You are responsible for maintaining adequate security and control of any and all IDs, passwords, hints, API keys or any other codes that you use to access the Xend Finance Services. Any loss or compromise of the foregoing information and/or your personal information may result in unauthorized access to your Xend Finance Account by third-parties and the loss or theft of any Digital Asset and/or funds held in your Xend Finance Account and any associated accounts, including your linked bank account(s) and credit/debit card(s). 
                        You are responsible for keeping your email address and telephone number up to date in your Account Profile in order to receive any notices or alerts that we may send you. We assume no responsibility for any loss that you may sustain due to compromise of account login credentials due to no fault of Xend Finance and/or failure to follow or act on any notices or alerts that we may send to you. 
                        In the event you believe your Xend Finance Account information has been compromised, contact Xend Finance Support immediately at support email needed 
                    </p>

                    <p>
                        It is your sole responsibility to determine whether, and to what extent, any taxes apply to any transactions you conduct through the Xend Finance Services, and to withhold, collect, report and remit the correct amounts of taxes to the appropriate tax authorities. 
                        Your transaction history is available through your Xend Finance Account.
                    </p>

                    <p>
                        If Xend Finance is holding funds in your account, and Xend Finance is unable to contact you and has no record of your use of the Services for several years, applicable law may require Xend Finance to report these funds as unclaimed property to the applicable jurisdiction. If this occurs, Xend Finance will try to locate you at the address shown in our records, but if Xend Finance is unable to locate you, it may be required to deliver any such funds to the applicable or jurisdiction as unclaimed property.
                    </p>

                    <p>Xend Finance reserves the right to deduct a dormancy fee or other administrative charges from such unclaimed funds, as permitted by applicable law.</p>

                </div>

                <div className="terms-box">
                    <span className="policy">7. Miscellaneous</span>

                    <p><strong>Force Majeure</strong> - We shall not be liable for delays, failure in performance or interruption of service which result directly or indirectly from any cause or condition beyond our reasonable control, including but not limited to, any delay or failure due to any act of God, pandemic, act of civil or military authorities, act of terrorists, civil disturbance, war, strike or other labor dispute, fire, interruption in telecommunications or Internet services or network provider services, failure of equipment and/or software, other catastrophe or any other occurrence which is beyond our reasonable control and shall not affect the validity and enforceability of any remaining provisions.</p>

                    <p><strong>Indemnification</strong> - If you have a dispute with one or more users of the Xend Finance services, you release Xend Finance, its affiliates and service providers, and each of their respective officers, directors, agents, joint venturers, employees and representatives from any and all claims, demands and damages (actual and consequential) of every kind and nature arising out of or in any way connected with such disputes. You agree to indemnify and hold Xend Finance, its affiliates and Service Providers, and each of its or their respective officers, directors, agents, joint venturers, employees and representatives, harmless from any claim or demand (including attorneys' fees and any fines, fees or penalties imposed by any regulatory authority) arising out of or related to your breach of this Agreement or your violation of any law, rule or regulation, or the rights of any third party.</p>

                    <p><strong>Limitation of Liability</strong> - IN NO EVENT SHALL XEND FINANCE, ITS AFFILIATES AND SERVICE PROVIDERS, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES OR REPRESENTATIVES, BE LIABLE 
                        (A) FOR ANY AMOUNT GREATER THAN THE VALUE OF THE SUPPORTED DIGITAL ASSET ON DEPOSIT IN YOUR XEND FINANCE ACCOUNT OR 
                        (B) FOR ANY LOST PROFITS OR ANY SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE, OR CONSEQUENTIAL DAMAGES, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH AUTHORIZED OR UNAUTHORIZED USE OF THE XEND FINANCE APP OR XEND FINANCE SERVICES, OR THIS AGREEMENT, EVEN IF AN AUTHORIZED REPRESENTATIVE OF XEND FINANCE HAS BEEN ADVISED OF OR KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. THIS MEANS, BY WAY OF EXAMPLE ONLY (AND WITHOUT LIMITING THE SCOPE OF THE PRECEDING SENTENCE), THAT IF YOU CLAIM THAT XEND FINANCE FAILED TO PROCESS A BUY OR SELL TRANSACTION PROPERLY, YOUR DAMAGES ARE LIMITED TO NO MORE THAN THE VALUE OF THE SUPPORTED DIGITAL ASSETS AT ISSUE IN THE TRANSACTION, AND THAT YOU MAY NOT RECOVER FOR LOST PROFITS, LOST BUSINESS OPPORTUNITIES, OR OTHER TYPES OF SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE, 
                        OR CONSEQUENTIAL DAMAGES IN EXCESS OF THE VALUE OF THE SUPPORTED DIGITAL ASSETS AT ISSUE IN THE TRANSACTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. XEND FINANCE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, XEND FINANCE SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. XEND FINANCE DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE APP, ANY PART OF THE XEND FINANCE SERVICES, OR ANY OF THE MATERIALS CONTAINED THEREIN, WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR ERROR-FREE.
                    </p>

                    <p><strong>Historical Price Data</strong> - Xend Finance makes no representations about the accuracy or completeness of historical Digital Asset price data available on the App.</p>

                    <p>Xend Finance will make reasonable efforts to ensure that requests for electronic debits and credits involving transactions are processed in a timely manner but Xend Finance makes no representations or warranties regarding the amount of time needed to complete processing which is dependent upon many factors outside of our control.</p>

                    <p><strong>Computer Viruses</strong> - We shall not bear any liability, whatsoever, for any damage or interruptions caused by any computer viruses, spyware, scareware, Trojan horses, worms or other malware that may affect your mobile phone, computer or other equipment, or any phishing, spoofing or other attack. We advise the regular use of a reputable and readily available virus screening and prevention software. You should also be aware that SMS and email services are vulnerable to spoofing and phishing attacks and should use care in reviewing messages purporting to originate from Xend Finance. Always log into your Xend Finance Account through the Xend Finance App to review any transactions or required actions if you have any uncertainty regarding the authenticity of any communication or notice.</p>

                    <p><strong>Entire Agreement</strong> - This Agreement, the Privacy Policy, E-Sign Consent and Appendices incorporated by reference herein comprise the entire understanding and agreement between you and Xend Finance as to the subject matter hereof, and supersedes any and all prior discussions, agreements and understandings of any kind (including without limitation any prior versions of this Agreement), and every nature between and among you and Xend Finance. Section headings in this Agreement are for convenience only, and shall not govern the meaning or interpretation of any provision of this Agreement.</p>

                    <p><strong>Amendments</strong> - We may amend or modify this Agreement at any time without posting on the Xend Finance App or emailing to you the revised Agreement, and the revised Agreement shall be effective at such time. If you do not agree with any such modification, your sole and exclusive remedy is to terminate your use of the Services and request to close your account. You agree that we shall not be liable to you or any third party for any modification or termination of Xend Finance Services, or suspension or termination of your access to Xend Finance Services, except to the extent otherwise expressly set forth herein. If the revised Agreement includes a material change, we will endeavour to provide you advanced notice via our webApp and/or email before the material change becomes effective.</p>

                    <p><strong>Assignment</strong> - You may not assign any rights and/or licenses granted under this Agreement. We reserve the right to assign our rights without restriction, including without limitation to any Xend Finance affiliates or subsidiaries, or to any successor in interest of any business associated with Xend Finance Services. Any attempted transfer or assignment in violation hereof shall be null and void. Subject to the foregoing, this Agreement will bind and inure to the benefit of the parties, their successors and permitted assigns.</p>

                    <p><strong>Severability</strong> - If any provision of this Agreement shall be determined to be invalid or unenforceable under any rule, law or regulation or any governmental agency, local, state, or federal, such provision will be changed and interpreted to accomplish the objectives of the provision to the greatest extent possible under any applicable law and the validity or enforceability of any other provision of this Agreement shall not be affected.</p>

                    <p><strong>Change of Control</strong> - In the event that Xend Finance is acquired by or merged with a third-party entity, we reserve the right, in any of these circumstances, to transfer or assign the information we have collected from you as part of such merger, acquisition, sale, or other change of control.</p>

                    <p><strong>Survival</strong> - All provisions of this Agreement which by their nature extend beyond the expiration or termination of this Agreement, including, without limitation, sections pertaining to suspension or termination, Xend Finance Account cancellation, debts owed to Xend Finance, the general use of the Xend Finance App, disputes with Xend</p>

                    <p>
                        Finance, and general provisions, shall survive the termination or expiration of this Agreement.
                        Governing Law - This Agreement will be governed by the laws in force in the British Virgin Islands. 
                    </p>

                    <p><strong>Complaints and Disputes</strong> - If you have any feedback, questions, or complaints, contact us via our customer support webpage at https://www.xend.finance or email  support@xend.africa. When you contact us please provide us with your name, address, and any other information we may need to identify you, your Xend Finance Account, and the transaction on which you have feedback, questions, or complaints.</p>

                    <p>In the event of a complaint, please set out the cause of your complaint, how you would like us to resolve the complaint and any other information you believe to be relevant. We will acknowledge your complaint within 24 hours of its receipt if you contact us via our Customer Support webpage or email us at support@xend.africa.</p>

                    <p>You agree to use the complaints procedure before filing any claim in a competent court. Failure to comply with this provision may be used as evidence of your unwillingness to settle the issue and/or the vexatious nature of the complaint.</p>
                </div>

                <div className="terms-box">
                    <span className="appendix-heading">APPENDIX I: PROHIBITED USE, PROHIBITED BUSINESSES AND CONDITIONAL USE</span>

                    <span className="policy">Prohibited Use</span>
                    <p>
                        You may not use your Xend Finance Account to engage in the following categories of activity ("Prohibited Uses"). 
                        The specific types of use listed below are representative, but not exhaustive. 
                        If you are uncertain as to whether or not your use of Xend Finance Services involves a Prohibited Use, or have questions about how these requirements apply to you, please contact us at support@xend.africa. 
                        By opening a Xend Finance Account, you confirm that you will not use your Account to do any of the following:
                    </p>
                    <p>
                        <strong>Unlawful Activity</strong>: Activity which would violate, or assist in the violation of, any law, statute, ordinance, or regulation, sanctions programs administered in the country where Xend Finance conducts business, or which would involve proceeds of any unlawful activity; publish, distribute or disseminate any unlawful material or information.
                    </p>
                    <p>
                        <strong>Abusive Activity</strong>: Actions which impose an unreasonable or disproportionately large load on our infrastructure, or detrimentally interfere with, intercept, or expropriate any system, data, or information; transmit or upload any material to the Xend Finance App that contains viruses, trojan horses, worms, or any other harmful or deleterious programs; attempt to gain unauthorized access to the Xend Finance App, other Xend Finance Accounts, computer systems or networks connected to the Xend Finance App, through password mining or any other means; use Xend Finance Account information of another party to access or use the Xend Finance App, except in the case of specific Merchants and/or applications which are specifically authorized by a user to access such user's Xend Finance Account and information; or transfer your account access or rights to your account to a third party, unless by operation of law or with the express permission of Xend Finance.
                    </p>
                    <p>
                        <strong>Abuse Other Users</strong>: Interfere with another individual's or entity's access to or use of any Xend Finance Services; defame, abuse, extort, harass, stalk, threaten or otherwise violate or infringe the legal rights (such as, but not limited to, rights of privacy, publicity and intellectual property) of others; incite, threaten, facilitate, promote, or encourage hate, racial intolerance, or violent acts against others; harvest or otherwise collect information from the Xend Finance App about others, including without limitation email addresses, without proper consent.
                    </p>
                    <p>
                        <strong>Fraud</strong>: Activity which operates to defraud Xend Finance, Xend Finance users, or any other person; provide any false, inaccurate, or misleading information to Xend Finance.
                    </p>
                    <p>
                        <strong>Gambling</strong>: Lotteries; bidding fee auctions; sports forecasting or odds making; fantasy sports leagues with cash prizes; internet gaming; contests; sweepstakes; games of chance.
                        Intellectual Property Infringement: Engage in transactions involving items that infringe or violate any copyright, trademark, right of publicity or privacy or any other proprietary right under the law, including but not limited to sales, distribution, or access to counterfeit music, movies, software, or other licensed materials without the appropriate authorization from the rights holder; use of Xend Finance intellectual property, name, or logo, including use of Xend Finance trade or service marks, without express consent from Xend Finance or in a manner that otherwise harms Xend Finance or the Xend Finance brand; any action that implies an untrue endorsement by or affiliation with Xend Finance.
                    </p>

                    <span className="policy">1. Prohibited Businesses</span>

                    <p>
                        In addition to the Prohibited Uses described above, the following categories of businesses, business practices, and sale items are barred from Xend Finance Services ("Prohibited Businesses"). 
                        If you are uncertain as to whether or not your use of Xend Finance Services involves a Prohibited Business, or have questions about how these requirements apply to you, please contact us at support@xend.africa.
                    </p>

                    <p>By opening a Xend Finance Account, you confirm that you will not use Xend Finance Services in connection with any of the following businesses, activities, practices, or items:</p>

                    <p>
                        <strong>Restricted Financial Services</strong>: Check cashing, bail bonds; collections agencies.
                        Intellectual Property or Proprietary Rights Infringement: Sales, distribution, or access to counterfeit music, movies, software, or other licensed materials without the appropriate authorization from the rights holder.
                    </p>
                    <p>
                        <strong>Pseudo-Pharmaceuticals</strong>: Pharmaceuticals and other products that make health claims that have not been approved or verified by the applicable local and/or national regulatory body.
                        Substances designed to mimic illegal drugs: Sale of a legal substance that provides the same effect as an illegal drug (e.g., salvia, kratom).
                        Adult Content and Services: Pornography and other obscene materials (including literature, imagery and other media); sites offering any sexually-related services such as prostitution, escorts, pay-per-view, adult live chat features.
                    </p>
                    <p>
                        <strong>Multi-level Marketing</strong>: Pyramid schemes, network marketing, and referral marketing programs.
                        Unfair, predatory or deceptive practices: Investment opportunities or other services that promise high rewards; Sale or resale of a service without added benefit to the buyer; resale of government offerings without authorization or added value; sites that we determine in our sole discretion to be unfair, deceptive, or predatory towards consumers.
                    </p>

                    <span className="policy">2. Conditional Use</span>

                    <p>
                        Express written consent and approval from Xend Finance must be obtained prior to using Xend Finance Services for the following categories of business and/or use ("Conditional Uses"). 
                        Consent may be requested by contacting support@xend.africa. Xend Finance may also require you to agree to additional conditions, make supplemental representations and warranties, complete enhanced on-boarding procedures, and operate subject to restrictions if you use Xend Finance Services in connection with any of the following businesses, activities, or practices:
                    </p>

                    <p>
                        <strong>Money Services</strong>: Money transmitters, Digital Currency transmitters; currency or Digital Currency exchanges or dealers; gift cards; prepaid cards; sale of in-game currency unless the merchant is the operator of the virtual world; act as a payment intermediary or aggregator or otherwise resell any of the Xend Finance Services.<br/>
                        Games of Skill: Games that are not defined as gambling under this Agreement or by law, but which require an entry fee and award a prize.
                    </p>

                    <p><strong>Religious/Spiritual Organizations</strong>: Operation of a for-profit religious or spiritual organization.</p>

                    <span className="appendix-heading">APPENDIX II: VERIFICATION PROCEDURES AND LIMITS</span>
                    <p>
                        Xend Finance uses multi-level systems and procedures to collect and verify information about you in order to protect Xend Finance and the community from fraudulent users, and to keep appropriate records of Xend Finance's customers. 
                        Your access to one or more Xend Finance Services, and limits including withdrawal and trading limits, quick buy limits (for guest users), Xend Finance Wallet transfer limits, and limits on transactions from a payment method may be based on the identifying information and/or proof of identity you provide to Xend Finance and the limits set by your financial institution.
                    </p>

                    <p>Xend Finance may require you to provide or verify additional information, or to wait some amount of time after completion of a transaction, before permitting you to use any Xend Finance Services and/or before permitting you to engage in transactions beyond certain volume limits.</p>

                    <p>You may contact your financial institution to enquire about your daily/weekly limits, and to request larger limits.</p>

                    <span className="appendix-heading">APPENDIX III: E-SIGN DISCLOSURE AND CONSENT</span>

                    <p>This policy describes how Xend Finance delivers communications to you electronically. 
                        We may amend this policy at any time by providing a revised version on our website. 
                        The revised version will be effective at the time we post it. 
                        We will provide you with prior notice of any material changes via our website.
                    </p>

                    <p>
                        Electronic Delivery of Communications You agree and consent to receive electronically all communications, agreements, documents, notices and disclosures (collectively, "Communications") that we provide in connection with your Xend Finance Account and your use of Xend Finance Services. Communications include:
                    </p>

                    <p>Terms of use and policies you agree to include updates to these agreements or policies;
                        Account details, history, transaction receipts, confirmations, and any other Account or transaction information;
                        Legal, regulatory, and tax disclosures or statements we may be required to make available to you; and
                        Responses to claims or customer support inquiries filed in connection with your Account.
                        We will provide these Communications to you by posting them on the Xend Finance website, emailing them to you at the primary email address listed in your Xend Finance profile, communicating to you via instant chat, and/or through other electronic communication such as text message.
                    </p>

                    <p>Hardware and Software Requirements In order to access and retain electronic Communications, you will need the following computer hardware and software:
                        A device with an Internet connection;
                        A current web browser that includes 128-bit encryption (e.g. Internet Explorer version 9.0 and above, Firefox version 3.6 and above, Chrome version 31.0 and above, or Safari 7.0 and above) with cookies enabled;
                        A valid email address (your primary email address on file with Xend Finance); and
                        Sufficient storage space to save past Communications or an installed printer to print them.
                        How to Withdraw Your Consent You may withdraw your consent to receive Communications electronically by e-mailing us at support@xend.africa. If you fail to provide or if you withdraw your consent to receive Communications electronically, Xend Finance reserves the right to immediately close your Account or charge you additional fees for paper copies.
                    </p>

                    <p>Updating your Information It is your responsibility to provide us with a true, accurate and complete e-mail address and your contact information, and to keep such information up to date. 
                        You understand and agree that if Xend Finance sends you an electronic Communication but you do not receive it because your primary email address on file is incorrect, out of date, blocked by your service provider, or you are otherwise unable to receive electronic Communications, Xend Finance will be deemed to have provided the Communication to you.
                    </p>

                    <p>You may update your information by logging into your account and visiting account settings or by contacting our support team via e-mail at support@xend.africa.</p>
                </div>
            </section>
        </div>
    );
}